import SelectOEM from "../components/commonPerfEcon/SelectOEM";
import SelectOEM2 from "../components/commonPerfEcon/SelectOEM2";
import OEMSelection from "../components/commonPerfEcon/OEMSelection";
import AircraftEngine from "../components/commonPerfEcon/AircraftEngine";
import AircraftEngine2 from "../components/commonPerfEcon/AircraftEngine2";
import SelectedAircraft from "../components/commonPerfEcon/SelectedAircraft";
import RoutesAndDistances from "../components/commonPerfEcon/RoutesAndDistances";
import RoutesAndDistances2 from "../components/commonPerfEcon/RoutesAndDistances2";
import CustomConfigWeights from "../components/commonPerfEcon/CustomConfigWeights";
import CustomConfigWeights2 from "../components/commonPerfEcon/CustomConfigWeights2";
import EnvironmentalConditions from "../components/commonPerfEcon/EnvironmentalConditions";
import AirportConditions from "../components/commonPerfEcon/AirportConditions";
import CustomAirportConditions from "../components/commonPerfEcon/CustomAirportConditions";
import CustomAirportConditions2 from "../components/commonPerfEcon/CustomAirportConditions2";
import CustomAirportConditions3 from "../components/commonPerfEcon/CustomAirportConditions3";
import InputReview from "../components/commonAll/InputReview";
import LogoUpload from "../components/commonAll/LogoUpload";
import Submission from "../components/commonAll/Submission";
import Thanks from "../components/commonAll/Thanks";

export const PerformanceForm = {
	SelectOEM,
	SelectOEM2,
	OEMSelection,
	AircraftEngine,
	AircraftEngine2,
	SelectedAircraft,
	RoutesAndDistances,
	RoutesAndDistances2,
	CustomConfigWeights,
	CustomConfigWeights2,
	EnvironmentalConditions,
	AirportConditions,
	CustomAirportConditions,
	CustomAirportConditions2,
	CustomAirportConditions3,
	InputReview,
	LogoUpload,
	Submission,
	Thanks,
};
