import React, { useEffect, useState } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useDispatch, useSelector } from "react-redux";
import { checkedValidation } from "../../reducers/inputReviewValidationSlice";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const InputReview = (props) => {
	// This is a summary of all the inputs. The data will be taken from the redux states.
	// Rendering of information will be based on package level that is passed in from parent.

	const dispatch = useDispatch();
	let location = useLocation();

	// redux calls
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let inputReviewValidation = useSelector(
		(state) => state.inputReviewValidation.value
	);
	let econData = useSelector((state) => state.econData);
	let perfData = useSelector((state) => state.performanceData);

	// validation checkbox
	let checkboxNodes = document.querySelectorAll('input[type="checkbox"]');
	const [validationChecked, setValidationChecked] = useState(false);

	// data in input review component
	let data;
	let prevChoice;
	if (packageType === "Performance") {
		data = perfData;
	} else if (packageType === "Economic") {
		data = econData;
		prevChoice = data.airportNavigationChoice;
	}

	const allEqual = (arr) => arr.every((val) => val === arr[0]);

	const generateRoutesRows = () => {
		const fillRows = (arr) => {
			if (arr.customDist && arr.iataAirportCode && arr.gcd) {
				let filledGCD = Array.from({ length: 30 }, (_) => ["-", "-"]);
				arr.gcd.forEach((value, index) => {
					filledGCD[value[1][0]][value[1][1]] = value[0];
				});
				let rows = Array(10).fill(Array(7).fill("-"));
				for (let i = 0; i < arr.iataAirportCode.length; i++) {
					let apRow = [
						arr.iataAirportCode[i][0],
						arr.iataAirportCode[i][1],
						arr.iataAirportCode[i][2],
					];
					let gcdRow = [filledGCD[i][0], filledGCD[i][1]];
					let distRow = [arr.customDist[i][0], arr.customDist[i][1]];
					rows[i] = apRow.concat(gcdRow).concat(distRow);
				}
				let filteredRows = [];
				rows.forEach((value) => {
					value.forEach((innerValue, index) => {
						if (innerValue === "" || innerValue === "-") {
							value[index] = "-";
						}
						if (typeof innerValue === "object") {
							if (innerValue[0] === "__" || !innerValue[0]) {
								value[index] = "-";
							}
						}
					});
					if (!allEqual(value)) {
						filteredRows.push(value);
					}
				});
				return filteredRows;
			} else {
				return [];
			}
		};
		let finalArr = fillRows(data.RnD).concat(fillRows(data.RnD2));
		return finalArr;
	};

	const generateAirportsRows = () => {
		const fillRows = (arr) => {
			console.log(arr);
			if (
				arr.airports &&
				arr.ISA &&
				arr.OAT &&
				arr.rwyWind &&
				arr.taxiOut &&
				arr.taxiIn
			) {
				let filledISA = Array(60).fill("-");
				arr.ISA.forEach((value) => {
					filledISA[value[1][0]] = value[0];
				});
				let filledRwy = Array(60).fill("-");
				arr.rwyWind.forEach((value) => {
					filledRwy[value[1][0]] = value[0];
				});
				let rows = Array(15).fill(Array(6).fill("-"));
				for (let i = 0; i < arr.airports.length; i++) {
					let airportRow = [arr.airports[i]];
					let isaRow = [filledISA[i]];
					let oatRow = [arr.OAT[i]];
					let rwyRow = [filledRwy[i]];
					let taxiOutRow = [arr.taxiOut[i]];
					let taxiInRow = [arr.taxiIn[i]];

					let override = [arr.override[i]];
					rows[i] = airportRow
						.concat(isaRow)
						.concat(oatRow)
						.concat(rwyRow)
						.concat(taxiOutRow)
						.concat(taxiInRow)
						.concat(override);
				}
				let filteredRows = [];
				rows.forEach((value) => {
					value.forEach((innerValue, index) => {
						if (innerValue === "" || innerValue === "-") {
							value[index] = "-";
						}
						if (typeof innerValue === "object") {
							if (innerValue[0] === "__" || !innerValue[0]) {
								value[index] = "-";
							}
						}
					});

					if (!allEqual(value)) {
						filteredRows.push(value);
					}
				});
				return filteredRows;
			} else {
				return [];
			}
		};
		let finalArr = fillRows(data.customAirportConditions).concat(
			fillRows(data.customAirportConditions2).concat(
				fillRows(data.customAirportConditions3)
			)
		);
		console.log(finalArr);
		return finalArr;
	};
	const generatePerfEcon = () => {
		const output = [
			[
				// packageLevel === "Premium"
				packageLevel === "Advanced" || packageLevel === "Premium"
					? ["Aircraft type #1:", `${data.acType}`]
					: ["Aircraft type:", `${data.acType}`],

				["Custom seats:", `${data.config.seats}`],
				["Weight unit:", `${data.config.unit}`],
				["Custom MTOW:", `${data.config.mtow}`],
				["Custom MLW:", `${data.config.mlw}`],
				["Custom OWE:", `${data.config.owe}`],
			],
			[
				packageType === "Economic"
					? ["Aircraft utilization: ", `${data.conditions.aircraftUtil} hrs`]
					: [],
				[
					"Speed: climb / cruise / descent:",
					`${data.conditions.climbSpeed} 
                                                        / ${data.conditions.cruiseSpeed}
                                                        / ${data.conditions.descentSpeed}`,
				],
				[
					"Fixed Mach (if selected):",
					`${
						data.conditions.fixedMach && data.conditions.fixedMach.length > 1
							? "M 0."
							: "None"
					}${data.conditions.fixedMach}`,
				],
				[
					"Diversion speed: climb / cruise / descent",
					`${data.conditions.divClimbSpeed} 
                                                        / ${data.conditions.divCruiseSpeed}
                                                        / ${data.conditions.divDescSpeed}`,
				],
				[
					"Diversion Fixed Mach (if selected):",
					`${
						data.conditions.divFixedMach &&
						data.conditions.divFixedMach.length > 1
							? "M 0."
							: "None"
					}${data.conditions.divFixedMach}`,
				],
			],

			// packageLevel === "Premium"
			(packageLevel === "Advanced" || packageLevel === "Premium") &&
			data.numberAircraft > 1
				? [
						["Aircraft type #2", `${data.acType2}`],
						["Custom seats:", `${data.configTwo.seats}`],
						[
							"Weight unit:",
							`${data.configTwo.unit ? data.configTwo.unit : data.config.unit}`,
						],
						["Custom MTOW:", `${data.configTwo.mtow}`],
						["Custom MLW:", `${data.configTwo.mlw}`],
						["Custom OWE:", `${data.configTwo.owe}`],
				  ]
				: [],
			// packageLevel === "Premium"
			(packageLevel === "Advanced" || packageLevel === "Premium") &&
			data.numberAircraft > 1
				? [
						packageType === "Economic"
							? [
									"Aircraft utilization: ",
									`${data.conditionsTwo.aircraftUtil2} hrs`,
							  ]
							: [],
						[
							"Speed: climb / cruise / descent:",
							`${data.conditionsTwo.climbSpeed} 
                                                            / ${data.conditionsTwo.cruiseSpeed}
                                                            / ${data.conditionsTwo.descentSpeed}`,
						],
						[
							"Fixed Mach (if selected):",
							`${
								data.conditionsTwo.fixedMach &&
								data.conditionsTwo.fixedMach.length > 1
									? "M 0."
									: "None"
							}${data.conditionsTwo.fixedMach}`,
						],
						[
							"Diversion speed: climb / cruise / descent",
							`${data.conditionsTwo.divClimbSpeed} 
                                                            / ${data.conditionsTwo.divCruiseSpeed}
                                                            / ${data.conditionsTwo.divDescSpeed}`,
						],
						[
							"Diversion Fixed Mach (if selected):",
							`${
								data.conditionsTwo.divFixedMach &&
								data.conditionsTwo.divFixedMach.length > 1
									? "M 0."
									: "None"
							}${data.conditionsTwo.divFixedMach}`,
						],
				  ]
				: [],

			// packageLevel === "Premium"
			packageLevel === "Premium" && data.numberAircraft > 2
				? [
						["Aircraft type #3", `${data.acType3}`],
						["Custom seats:", `${data.configThree.seats}`],
						[
							"Weight unit:",
							`${
								data.configThree.unit ? data.configThree.unit : data.config.unit
							}`,
						],
						["Custom MTOW:", `${data.configThree.mtow}`],
						["Custom MLW:", `${data.configThree.mlw}`],
						["Custom OWE:", `${data.configThree.owe}`],
				  ]
				: [],
			// packageLevel === "Premium"
			packageLevel === "Premium" && data.numberAircraft > 2
				? [
						packageType === "Economic"
							? [
									"Aircraft utilization: ",
									`${data.conditionsThree.aircraftUtil3} hrs`,
							  ]
							: [],
						[
							"Speed: climb / cruise / descent:",
							`${data.conditionsThree.climbSpeed} 
                                                            / ${data.conditionsThree.cruiseSpeed}
                                                            / ${data.conditionsThree.descentSpeed}`,
						],
						[
							"Fixed Mach (if selected):",
							`${
								data.conditionsThree.fixedMach &&
								data.conditionsThree.fixedMach.length > 1
									? "M 0."
									: "None"
							}${data.conditionsThree.fixedMach}`,
						],
						[
							"Diversion speed: climb / cruise / descent",
							`${data.conditionsThree.divClimbSpeed} 
                                                            / ${data.conditionsThree.divCruiseSpeed}
                                                            / ${data.conditionsThree.divDescSpeed}`,
						],
						[
							"Diversion Fixed Mach (if selected):",
							`${
								data.conditionsThree.divFixedMach &&
								data.conditionsThree.divFixedMach.length > 1
									? "M 0."
									: "None"
							}${data.conditionsThree.divFixedMach}`,
						],
				  ]
				: [],

			[
				["En route temperature variance:", `${data.conditions.temp} (°C)`],
				["En route seasonal wind:", `${data.conditions.wind}`],
				["En route wind probability:", `${data.conditions.windProb}%`],
				["Default airport temperature:", `${data.airportConditions.ISA} (°C)`],
				[
					"Default airport runway wind:",
					`${data.airportConditions.rwyWind} (kts)`,
				],
				["Fuel reserve policy:", `${data.conditions.fuelReserve}`],
				["Payload criteria:", `${data.conditions.payloadCrit}`],
				["Pax load factor:", `${data.conditions.payloadFactor}%`],
				[
					"Passenger & baggage allowance:",
					`${data.conditions.pbAllowance} ${data.config.unit}`,
				],
				[
					"Default taxi out / in:",
					`${data.airportConditions.taxiOut} min / ${data.airportConditions.taxiIn} min`,
				],
				["Distance unit:", `${data.RnD.distanceUnit}`],
			],
		].filter((elem) => elem.length !== 0);
		console.log(output);
		return (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.1 }}
			>
				<UtilityClass.InputReview sections={output} title={true} />
				{data.RnD.customDist && data.RnD.iataAirportCode && data.RnD.gcd && (
					<>
						<br />
						<UtilityClass.CustomText
							content="Routes Summary"
							textSize="large"
							fontWeight="semibold"
						/>
						<UtilityClass.DefaultTable
							headerList={[
								"From",
								"To",
								"Diversion",
								"Route GCD Factor (%)",
								"Diversion GCD Factor (%)",
								"Route Distance (NM)",
								"Diversion Distance (NM)",
							]}
							rows={generateRoutesRows()}
						></UtilityClass.DefaultTable>
					</>
				)}

				{/* {data.customAirportConditions.airports &&
					data.customAirportConditions.ISA &&
					data.customAirportConditions.OAT &&
					data.customAirportConditions.taxiIn &&
					data.customAirportConditions.taxiOut &&
					data.customAirportConditions.rwyWind &&
					!(
						data.customAirportConditions.airports.length > 0 &&
						data.customAirportConditions.ISA.length === 0 &&
						data.customAirportConditions.OAT.length > 0 &&
						data.customAirportConditions.taxiIn.length > 0 &&
						data.customAirportConditions.taxiOut.length > 0 &&
						data.customAirportConditions.rwyWind.length === 0
					) && ( */}
				{data.airportConditions.input == "Yes, add custom inputs." && (
					<>
						<br />
						<UtilityClass.CustomText
							content="Airports Summary"
							textSize="large"
							fontWeight="semibold"
						/>

						<UtilityClass.CustomText
							content="Default data is listed in black"
							textSize="medium"
							color={"grey"}
							fontWeight="semibold"
						/>
						<UtilityClass.DefaultTable
							headerList={[
								"Airport",
								"ISA (°C)",
								"OAT (°C)",
								"Rwy Winds (kts)",
								"Taxi Out (min)",
								"Taxi In (min)",
							]}
							rows={generateAirportsRows()}
						></UtilityClass.DefaultTable>
						<br />
					</>
				)}
				{/* )} */}

				<UtilityClass.BackNextButtons
					hideTextBox={true}
					nextButtonText={`${
						packageType === "Performance" ? "VALIDATION COMPLETE" : "NEXT"
					}`}
					backLink={
						packageLevel === "Basic"
							? "/forms/" +
							  packageType +
							  "/" +
							  `${
									packageType === "Performance"
										? "AirportConditions"
										: prevChoice === "Use only IATA Aviation Charges"
										? "AirportAndNavigationCharges"
										: "CustomAirportNavigationCharges"
							  }`
							: "/forms/" +
							  packageType +
							  "/" +
							  `${
									packageType === "Performance"
										? "AirportConditions"
										: prevChoice === "Use only IATA Aviation Charges"
										? "AirportAndNavigationCharges"
										: "CustomAirportNavigationCharges2"
							  }`
					}
					nextLink={
						"/forms/" +
						packageType +
						"/" +
						`${
							packageType === "Performance" ? "LogoUpload" : "InputReviewEcon"
						}`
					}
					dispatchInputs={dispatch(
						checkedValidation(checkboxNodes[0]?.checked)
					)}
					validation={
						location.pathname === `/forms/Economic/InputReview`
							? true
							: validationChecked
					}
					validationButton={packageType === "Performance" ? true : false}
					defaultValue={inputReviewValidation}
					value={setValidationChecked}
					required={true}
				/>
				<br />
			</motion.div>
		);
	};

	const generateDemand = () => {
		return (
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ duration: 0.1 }}
			>
				<UtilityClass.InputReview sections={props.inputs} />
				<>{props.additionalComponents}</>
				<UtilityClass.BackNextButtons
					hideTextBox={true}
					nextButtonText="VALIDATION COMPLETE"
					backLink={props.backLink}
					nextLink={props.nextLink}
					dispatchInputs={dispatch(
						checkedValidation(checkboxNodes[0]?.checked)
					)}
					validation={validationChecked}
					validationButton={true}
					defaultValue={inputReviewValidation}
					value={setValidationChecked}
					required={true}
				/>
			</motion.div>
		);
	};

	return (
		<>
			{(packageType === "Performance" || packageType === "Economic") &&
				generatePerfEcon()}
			{packageType === "Demand" && generateDemand()}
		</>
	);
};

export default InputReview;
