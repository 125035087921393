import React from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	selectEconLeaseRate,
	selectFinanceAssumptions,
	selectLocation,
	selectAircraftOwnershipChoice,
	selectEconAircraftInsurance,
	selectAircraftAcquisition3,
} from "../../reducers/econDataSlice";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const LeaseRate3 = (props) => {
	const { aircraftOwnership } = props;
	// Gets rendered when user selects "Lease" option in Fixed Operating Cost component
	let location = useLocation();
	let lease = useSelector((state) => state.econData.aircraftOwnership3);

	const [defaultLease, setDefaultLease] = useState(
		lease === undefined || lease.length === 0 ? false : true
	);

	const dispatch = useDispatch();
	const [leaseRate, setLeaseRate] = useState();
	const chooseLeaseRate = (v) => {
		setLeaseRate(v);
	};

	const dispatchInputs = () => {
		dispatch(selectLocation(location));
		dispatch(selectEconLeaseRate(handleDispatch()));
		dispatch(selectEconAircraftInsurance(handleDispatchInsurance()));
		dispatch(selectAircraftAcquisition3(aircraftAcquisitionPrice));
		dispatch(
			selectFinanceAssumptions({
				aircraftNbr: 3,
				data: {
					aircraftPrice: undefined,
					downPayment: undefined,
					interestRate: undefined,
					paymentTerm: undefined,
					marketValue: undefined,
				},
			})
		);
		dispatch(selectAircraftOwnershipChoice(handleDispatchOwnership()));
	};

	const handleDispatchInsurance = () => {
		return { aircraftNbr: 3, aircraftInsurance: aircraftInsurance };
	};

	const handleDispatchOwnership = () => {
		return { aircraftNbr: 3, aircraftOwnership: aircraftOwnership };
	};
	const handleDispatch = () => {
		return { aircraftNbr: 3, leaseRate: leaseRate };
	};

	// redux calls

	let econData = useSelector((state) => state.econData);
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let acType3 = econData.acType3;
	let leaseRateSelector = econData.leaseRate3;
	let acquisitionPrice = econData.aircraftAcquisition;
	let aircraftInsuranceSelector = econData.aircraftInsurance3;
	let insuranceData = econData.insuranceData;
	let insurance = econData.aircraftInsurance3;

	const [defaultAcquisitionPrice, setDefaultAcquisitionPrice] = useState(
		acquisitionPrice === undefined || acquisitionPrice.length === 0
			? false
			: true
	);
	const [defaultInsurance, setDefaultInsurance] = useState(
		insurance === undefined || insurance.length === 0 ? false : true
	);

	const [aircraftInsurance, setAircraftInsurance] = useState(insurance);
	const chooseAircraftInsurance = (v) => {
		setAircraftInsurance(v);
	};

	const [aircraftAcquisitionPrice, setAircraftAcquisitionPrice] =
		useState(acquisitionPrice);

	let leaseRateData = econData.leaseRateData.find((value) => {
		if (acType3.length > 0) {
			let current = acType3.split(",");
			return (
				value.engineType === current[1].trim() &&
				value.aircraftType === current[0].trim()
			);
		} else {
			return true;
		}
	});

	let acquisitionData = econData.acquisitionPriceData.find((value) => {
		if (acType3.length > 0) {
			let current = acType3.split(",");
			return (
				value.engineType === current[1].trim() &&
				value.aircraftType === current[0].trim()
			);
		} else {
			return true;
		}
	});

	const validateContinue = () => {
		let validated = false;
		if (leaseRate != "") {
			validated = true;
		} else {
			validated = false;
		}
		return validated;
	};

	return (
		<motion.div>
			<div className="max-w-md mx-auto">
				<UtilityClass.DefaultInput
					combinedDropdown={[true, leaseRateData]}
					defaultValue={leaseRateSelector}
					labelBool={true}
					label="Lease Rate"
					leftSquareLabelBool={true}
					leftSquareLabelText={"USD"}
					placeholder={"Lease rate"}
					rightSquareLabelBool={true}
					rightSquareLabelText={"per month"}
					value={chooseLeaseRate}
					width="full"
					includeDefaults={defaultLease}
					defaultVals={lease}
					regex={/^[0-9]{0,6}$/}
				/>
				<br />
				<UtilityClass.DefaultInput
					combinedDropdown={[true, acquisitionData]}
					defaultValue={aircraftAcquisitionPrice}
					labelBool={true}
					label="Market Values"
					toolTip={true}
					toolTipDesc={`Market values used to calculate aircraft insurance are provided as a function of aircraft age.`}
					placeholder={
						aircraftOwnership === "Finance" ? "Market Values" : "Market Values"
					}
					leftSquareLabelBool
					leftSquareLabelText={
						aircraftOwnership === "Finance" ? "Million USD" : "Million USD"
					}
					value={setAircraftAcquisitionPrice}
					width="full"
					includeDefaults={defaultAcquisitionPrice}
					regex={
						aircraftOwnership === "Finance"
							? /^(0?|[1-9]\d{0,2})(\.\d{0,2})?$/
							: /^[0-9]{0,6}$/
					}
					// defaultVals={acquisitionPrice}
				/>
				<UtilityClass.DefaultInput
					defaultValue={aircraftInsuranceSelector}
					combinedDropdown={[true, insuranceData[0]]}
					labelBool={true}
					label="Aircraft Insurance"
					placeholder={"Aircraft Insurance Rate"}
					rightSquareLabelBool={true}
					rightSquareLabelText={"% Per Year"}
					toolTip={true}
					toolTipDesc="% of value"
					value={chooseAircraftInsurance}
					width="full"
					includeDefaults={defaultInsurance}
					regex={/^(0?(\.\d{0,1})?|[1-9]\d{0,1}(\.\d{0,1})?|30(\.0)?)?$/}
					//  defaultVals={insurance}
				/>
			</div>
			<UtilityClass.BackNextButtons
				nextButtonText="NEXT"
				backLink={"/forms/" + packageType + "/FixedOperatingCost2"}
				nextLink={
					"/forms/" +
					packageType +
					`${
						packageLevel === "Basic"
							? "/CashOperatingCosts"
							: "/CashOperatingCosts"
					}`
				}
				dispatchInputs={dispatchInputs}
				validation={validateContinue()}
			/>
		</motion.div>
	);
};

export default LeaseRate3;
