import React, { useCallback, useEffect, useState } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useDispatch, useSelector } from "react-redux";
import {
	resetPerf,
	selectACList,
	selectFetched,
	selectNormalSpeedMachData,
	selectOEMList,
	selectOrderID,
	selectOrderNumber,
} from "../../reducers/performanceDataSlice";
import {
	resetEcon,
	selectAcquisitionPriceData,
	selectCabinCrewData,
	selectDownPaymentData,
	selectEconACList,
	selectEconFetched,
	selectEconNormalSpeedMachData,
	selectEconOEMList,
	selectEconOrderID,
	selectEconOrderNumber,
	selectFaPerAircraftData,
	selectFuelPriceData,
	selectInsuranceData,
	selectInterestRateData,
	selectLeaseRateData,
	selectMarketData,
	selectPaymentTermData,
	selectPilotCrewData,
	selectPilotsPerAircraftData,
	selectRegionData,
} from "../../reducers/econDataSlice";
import {
	chosePerformance,
	choseEconomic,
	choseDemand,
	resetType,
} from "../../reducers/packageTypeSlice";
import {
	choseBasic,
	choseAdvanced,
	chosePremium,
	resetLevel,
} from "../../reducers/packageLevelSlice";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import dispatcherList from "../../reducers/dispatcherList";
import { useSearchParams } from "react-router-dom";
import {
	resetDemand,
	selectDemandOrderID,
	selectDemandOrderNumber,
} from "../../reducers/demandDataSlice";
import { resetValidation } from "../../reducers/inputReviewValidationSlice";

const Fetcher = ({
	isLoaded,
	setIsLoaded,
	selected,
	setSelected,
	status,
	setStatus,
	secondarySelected,
	setSecondarySelected,
}) => {
	const [fetchedData, setFetchedData] = useState({});
	const [importedData, setImportedData] = useState({});

	const [primaryOrder, setPrimaryOrder] = useState({});
	const [remainingOrders, setRemainingOrders] = useState({});

	const [searchParams] = useSearchParams();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const orderNumber = searchParams.get("order");

	const existingPackageType = useSelector((state) => {
		switch (state.packageType.value) {
			case "Performance":
				return "performanceData";
			case "Economic":
				return "econData";
			case "Demand":
				return "demandData";
			default:
				return "Default";
		}
	});
	const existingOrderNumber = useSelector((state) => {
		if (existingPackageType !== "Default") {
			return state[existingPackageType].orderNumber;
		} else {
			return 0;
		}
	});

	const testURL = (url) => {
		if (url.length > 1) {
			let orderNumberSplit = url[1].split("?order=");
			if (orderNumberSplit.length === 1 && orderNumberSplit[0] !== "") {
				// the query param does not match ?order= or doesnt exist
				return false;
			} else if (orderNumberSplit.length > 1) {
				if (orderNumberSplit[0] === "" && orderNumberSplit[1] === "") {
					// the query param exists but the order number is missing
					return false;
				} else {
					let orderNumberCanditate = orderNumberSplit[1];
					// (^[0-9]*[\\/]?$) for the trailing slash
					const regex = new RegExp("^[0-9]*$");
					const result = regex.test(orderNumberCanditate);
					return result;
					// testing the order number for invalid format (such as a period at the end)
				}
			}
			return true;
		} else {
			return false;
		}
	};

	const initializeOrder = async (existingOrderNumber) => {
		let currentOrderNumber;
		let hrefOrderNumber = window.location.href.split("/?order=")[1];
		if (hrefOrderNumber !== existingOrderNumber) {
			currentOrderNumber = hrefOrderNumber;
		} else if (existingOrderNumber !== 0) {
			currentOrderNumber = existingOrderNumber;
		} else if (orderNumber) {
			currentOrderNumber = orderNumber;
		} else {
			currentOrderNumber = selected.id;
		}
		try {
			await fetch(
				`${
					process.env.REACT_APP_STAGE === "production"
						? process.env.REACT_APP_SERVER_URL_PROD
						: process.env.REACT_APP_SERVER_URL_DEV
				}/getOrder?order=${currentOrderNumber}`
			)
				.then((response) => response.json())
				.then((json) => {
					dispatch(selectOrderNumber(currentOrderNumber));
					dispatch(selectEconOrderNumber(currentOrderNumber));
					dispatch(selectDemandOrderNumber(currentOrderNumber));
					if (json.length === 0) {
						setStatus(
							"No matching order found. If this was a mistake please contact support."
						);
					} else {
						// this is only orders that in progress
						let filteredPrimary = json
							.filter((value) => {
								return (
									value.uuid === currentOrderNumber.toString() &&
									value.status !== "SUBMITTED"
								);
							})
							.sort((a, b) => a.uuid - b.uuid);

						// this is only orders that are in progress, to be added to the remaining orders below
						let filteredPrimaryLeftover = json
							.filter((value) => {
								return (
									value.uuid === currentOrderNumber.toString() &&
									value.status === "SUBMITTED"
								);
							})
							.sort((a, b) => a.uuid - b.uuid);

						if (
							filteredPrimary.length === 1 &&
							filteredPrimaryLeftover.length === 0
						) {
							let filteredRemaining = json
								.filter(
									(value) =>
										value.uuid !== currentOrderNumber.toString() &&
										value.status === "SUBMITTED" &&
										value.uuid < currentOrderNumber
								)
								.concat(filteredPrimaryLeftover)
								.sort((a, b) => a.uuid - b.uuid);

							// if the lenght of the filtered primary is ONE

							setPrimaryOrder(filteredPrimary);
							setRemainingOrders(filteredRemaining);
							setSelected({
								...selected,
								id: filteredPrimary[0].id,
								orderNumber: currentOrderNumber,
								package: filteredPrimary[0].package,
								packageType: filteredPrimary[0].packageType,
								status: filteredPrimary[0].status,
							});
							setStatus("Initializing using the selected order...");
						} else if (
							filteredPrimaryLeftover.length > 0 &&
							filteredPrimary.length === 0
						) {
							let filteredRemaining = json
								.filter(
									(value) =>
										value.uuid !== currentOrderNumber.toString() &&
										value.status === "SUBMITTED" &&
										value.uuid < currentOrderNumber
								)
								.concat(filteredPrimaryLeftover)
								.sort((a, b) => a.uuid - b.uuid);

							setPrimaryOrder(filteredPrimary);
							setRemainingOrders(filteredRemaining);
							setSelected({
								...selected,
								id: filteredPrimaryLeftover[0].id,
								orderNumber: currentOrderNumber,
								package: filteredPrimaryLeftover[0].package,
								packageType: filteredPrimaryLeftover[0].packageType,
								status: filteredPrimaryLeftover[0].status,
							});

							setStatus("Initializing using the selected order...");
						} else {
							let filteredRemaining = json
								.filter(
									(value) =>
										value.uuid !== currentOrderNumber.toString() &&
										value.status === "SUBMITTED" &&
										value.uuid < currentOrderNumber
								)
								.concat(filteredPrimaryLeftover)
								.sort((a, b) => a.uuid - b.uuid);

							setPrimaryOrder(filteredPrimary);
							setRemainingOrders(filteredRemaining);
							setSelected({
								...selected,
								id: filteredPrimary[0].id,
								orderNumber: currentOrderNumber,
								package: filteredPrimary[0].package,
								packageType: filteredPrimary[0].packageType,
								status: filteredPrimary[0].status,
							});
							if (
								filteredPrimary.length === 1 &&
								filteredRemaining.length === 0
							) {
								setStatus("Initializing using the selected order...");
							} else {
								setStatus("Previous packages detected...");
							}
						}
					}
				});
		} catch (error) {
			setStatus(
				"ERROR LOADING DATA, PRESS TO REFRESH. IF THE ISSUE PERSISTS CONTACT SUPPORT."
			);
		}
	};

	// right here we neeed to check this for a different package
	useEffect(() => {
		let currentLocationData = window.location.href.split("/forms/");
		let result = testURL(currentLocationData);
		dispatch(resetLevel());
		dispatch(resetType());
		dispatch(resetValidation());
		dispatch(resetPerf());
		dispatch(resetEcon());
		dispatch(resetDemand());
		if (!result) {
			setStatus(
				"No matching order found. If this was a mistake please contact support."
			);
		} else {
			if (currentLocationData[1] === "" && existingOrderNumber !== 0) {
				navigate(`/forms/?order=${existingOrderNumber}`, {
					replace: true,
				});
			}
			if (orderNumber === null && existingOrderNumber === 0) {
				setStatus(
					"No matching order found. If this was a mistake please contact support."
				);
			} else {
				setTimeout(() => initializeOrder(existingOrderNumber), 100);
			}
		}
	}, []);

	useEffect(() => {
		const fetchNewOrder = async (imported) => {
			try {
				await fetch(
					`${
						process.env.REACT_APP_STAGE === "production"
							? process.env.REACT_APP_SERVER_URL_PROD
							: process.env.REACT_APP_SERVER_URL_DEV
					}/aircraftEcon`
				)
					.then((response) => response.json())
					.then((json) => {
						if (json.every((e) => e.length === 0)) {
							setStatus(
								"ERROR LOADING DATA, PRESS TO REFRESH. IF THE ISSUE PERSISTS CONTACT SUPPORT."
							);
						} else {
							setIsLoaded(true);
							if (selected.package === "Performance") {
								dispatch(selectFetched(true));
							} else if (selected.package === "Economic") {
								dispatch(selectEconFetched(true));
							} else {
							}
							setFetchedData(json);
						}
					});
			} catch (error) {
				setStatus(
					"ERROR LOADING DATA, PRESS TO REFRESH. IF THE ISSUE PERSISTS CONTACT SUPPORT."
				);
			}
		};

		const fetchExistingOrder = async (secondary, packageType) => {
			let orderTable;
			switch (selected.package !== "Default" ? selected.package : packageType) {
				case "Performance":
					orderTable = "perforder";
					break;
				case "Economic":
					orderTable = "econorder";
					break;
				case "Demand":
					orderTable = "demandorder";
					break;
				default:
					orderTable = "";
					break;
			}
			try {
				await fetch(
					`${
						process.env.REACT_APP_STAGE === "production"
							? process.env.REACT_APP_SERVER_URL_PROD
							: process.env.REACT_APP_SERVER_URL_DEV
					}/getOrderData?orderID=${
						secondary ? secondary : selected.id
					}&table=${orderTable}`
				)
					.then((response) => response.json())
					.then((json) => {
						if (json.every((e) => e.length === 0)) {
							// set status instead
							// console.log("error on it ");
							// fetch(
							//     `${
							//         process.env.REACT_APP_STAGE === "production"
							//             ? process.env.REACT_APP_SERVER_URL_PROD
							//             : process.env.REACT_APP_SERVER_URL_DEV
							//     }/emptyOrder?orderID=${selected.id}`,
							//     {
							//         method: "POST",
							//         mode: "cors",
							//         headers: { "Content-Type": "application/json" },
							//     }
							// )
							//     .then((response) => response.json())
							//     .then((json) => {
							//         if (json.status === "Successful") {
							//             setTimeout(
							//                 () =>
							//                     navigate(
							//                         `/forms/?order=${selected.id}`,
							//                         { replace: true }
							//                     ),
							//                 600
							//             );
							//             setStatus(
							//                 "There was an error loading the data. Re-initializing..."
							//             );
							//             // this assumes that the data is invalid, so it resets to default state
							//             setDataFormat("IN PROGRESS");
							//             setTimeout(() => initializeOrder(), 600);
							//         } else {
							//             setStatus(
							//                 "ERROR LOADING DATA, PRESS TO REFRESH. IF THE ISSUE PERSISTS CONTACT SUPPORT."
							//             );
							//         }
							//     });
						} else {
							setImportedData(json);
							setIsLoaded(true);
							if (selected.package === "Performance") {
								dispatch(selectFetched(true));
							} else if (selected.package === "Economic") {
								dispatch(selectEconFetched(true));
								setStatus("Initializing using the selected order...");
							}
						}
					});
			} catch (error) {
				setStatus(
					"ERROR LOADING DATA, PRESS TO REFRESH. IF THE ISSUE PERSISTS CONTACT SUPPORT."
				);
			}
		};

		if (status === "Initializing using the selected order...") {
			if (selected.id !== 0 && selected.status === "SUBMITTED") {
				fetchNewOrder();
				fetchExistingOrder();
			} else if (secondarySelected.id !== 0) {
				fetchNewOrder();
				fetchExistingOrder(secondarySelected.id);
			} else {
				fetchNewOrder();
				setTimeout(() => {
					setStatus("Finished");
				}, 300);
			}
		}
	}, [
		dispatch,
		navigate,
		secondarySelected,
		selected,
		setIsLoaded,
		status,
		setStatus,
		importedData,
	]);

	const processData = useCallback(() => {
		// if package type is performance or economic then we do do these options and dispatch them here
		// else if demand then we only do a certain other subset of them
		let tempACList = [];
		let tempLeaseRateList = [];
		let tempMarketRateList = [];
		let tempDownPaymentList = [];
		let tempInterestRateList = [];
		let tempPaymentTermList = [];
		let tempRegionList = [];
		let tempAcquisitionPrice = [];
		let tempOEMList = [];
		let tempNormalSpeedMachData = [];

		// prevents occasional undefined values from mysql
		if (fetchedData[0] && fetchedData[1]) {
			// AIRCRAFT TABLES
			fetchedData[0].forEach((value, index) => {
				console.log(value);
				tempACList.push({
					manufacturer: value.manufacturer,
					aircraftType: value.aircraftType,
					engineType: value.engineType,
					configTotalSeats: value.configTotalSeats,
					maxTakeoffWeight: value.maxTakeoffWeight,
					maxLandingWeight: value.maxLandingWeight,
					operatingWeight: value.operatingWeight,
					maxPayload: value.maxPayload,
				});
				let machValue = value.normalOperatingMach.split(".")[1];
				tempNormalSpeedMachData.push({
					aircraftType: value.aircraftType,
					engineType: value.engineType,
					normalOperatingSpeed: value.normalOperatingSpeed,
					normalOperatingMach: machValue === "00" ? undefined : machValue,
				});
				if (selected.package === "Economic") {
					tempLeaseRateList.push({
						aircraftType: value.aircraftType,
						engineType: value.engineType,
						data: [
							[
								Math.trunc(value.currentLeaseRateLT5),
								"[Less than 5 years old]",
							],
							[Math.trunc(value.currentLeaseRate510), "[5 to 10 years old]"],
							[
								Math.trunc(value.currentLeaseRateGT10),
								"[More than 10 years old]",
							],
						],
						header: ["Monthly Rate [Vintage]"],
					});
					tempAcquisitionPrice.push({
						aircraftType: value.aircraftType,
						engineType: value.engineType,
						data: [
							[value.currentMarketValueLT5, "[Less than 5 years old]"],
							[value.currentMarketValue510, "[5 to 10 years old]"],
							[value.currentMarketValueGT10, "[More than 10 years old]"],
						],
						header: ["Market Values"],
					});
					tempMarketRateList.push({
						aircraftType: value.aircraftType,
						engineType: value.engineType,
						data: [
							[value.currentMarketValueLT5, "[Less than 5 years old]"],
							[value.currentMarketValue510, "[5 to 10 years old]"],
							[value.currentMarketValueGT10, "[More than 10 years old]"],
						],
						header: ["Market Value [Vintage]"],
					});
					tempDownPaymentList.push({
						aircraftType: value.aircraftType,
						engineType: value.engineType,
						data: Array.from(Array(5), (_, elem) => [(elem + 1) * 5, ""]),
						header: ["Please select"],
					});
					tempInterestRateList.push({
						aircraftType: value.aircraftType,
						engineType: value.engineType,
						data: Array.from(
							{ length: (6.0 - 3.0) / 0.5 + 1.0 },
							(value, index) => [Number(3.0 + index * 0.5).toFixed(1), ""]
						),
						header: ["Please select"],
					});
					tempPaymentTermList.push({
						aircraftType: value.aircraftType,
						engineType: value.engineType,
						data: Array.from({ length: 13 - 7 }, (value, index) => [
							7 + index,
							"",
						]),
						header: ["Please select"],
					});
					tempNormalSpeedMachData.push({
						aircraftType: value.aircraftType,
						engineType: value.engineType,
						normalOperatingSpeed: value.normalOperatingSpeed,
						normalOperatingMach: value.normalOperatingMach.split(".")[1],
					});
				}
			});
			tempOEMList = [
				...new Set(
					fetchedData[0].map((value, index) => {
						return value.manufacturer;
					})
				),
			];
		}

		// ECON TABLES
		let totalRegions = [];
		let tempFuelData = {};

		let tempCabinCrewRateList = [];
		let tempPilotCrewRateList = [];

		let tempCabinCrews = {};
		let tempPilotCrews = {};
		let tempPilotsPerAircraft = [];
		let tempFaPerAircraft = [];
		let tempInsuranceData = [];

		if (fetchedData[1]) {
			fetchedData[1].forEach((value) => {
				let fuelPrices = value.fuelPrice.split(",");
				if (fuelPrices.length === 3) {
					tempFuelData[value.region] = [
						fuelPrices[0].split(" "),
						fuelPrices[1].trim().split(" "),
						fuelPrices[2].trim().split(" "),
					];
					totalRegions.push(value.region);
					tempCabinCrews[value.region] = value.cabinCrewRate;
					tempPilotCrews[value.region] = value.pilotCrewRate;
					tempPilotCrews[value.region] = value.pilotCrewRate;
				}

				// tempCabinCrewRateList.push({
				//     region: value.region,
				//     data: [
				//         [Math.trunc(value.cabinCrewRate).toLocaleString(), ''],
				//     ],
				//     header: ['Per F/A Cost']
				// })
				// tempPilotCrewRateList.push({
				//     region: value.region,
				//     data: [
				//         [Math.trunc(value.pilotCrewRate).toLocaleString(), ''],
				//     ],
				//     header: ['Per F/A Cost']
				// })
			});
		}

		tempPilotsPerAircraft.push({
			data: [
				[2, ""],
				[3, ""],
				[4, ""],
			],
			header: ["Please select"],
		});
		tempInsuranceData.push({
			data: [
				[5, ""],
				[10, ""],
				[15, ""],
			],
			header: ["Please select"],
		});
		tempFaPerAircraft.push({
			data: [
				[2, ""],
				[3, ""],
				[4, ""],
			],
			header: ["Please select"],
		});

		tempRegionList.push({
			regions: totalRegions,
			header: ["Please select"],
		});

		tempCabinCrewRateList.push({
			crews: tempCabinCrews,
			header: ["Per F/A Cost"],
		});

		tempPilotCrewRateList.push({
			crews: tempPilotCrews,
			header: ["Pilot Crew Cost"],
		});

		// VERIFIED VALUES ONLY
		tempACList = fetchedData[0].filter((value, index) => {
			if (value.inputforms === "Yes") {
				return value;
			}
		});

		if (selected.package === "Performance") {
			dispatch(selectOEMList(tempOEMList));
			dispatch(selectACList(tempACList));
			dispatch(selectOrderID(selected.id));
			dispatch(selectNormalSpeedMachData(tempNormalSpeedMachData));
			//  dispatch(selectOrderNumber(selected))
		} else if (selected.package === "Economic") {
			dispatch(selectEconOEMList(tempOEMList));
			dispatch(selectEconACList(tempACList));
			dispatch(selectEconNormalSpeedMachData(tempNormalSpeedMachData));
			// dispatch(selectEconOrderNumber(selected))
			dispatch(selectLeaseRateData(tempLeaseRateList));
			dispatch(selectMarketData(tempMarketRateList));
			dispatch(selectDownPaymentData(tempDownPaymentList));
			dispatch(selectInterestRateData(tempInterestRateList));
			dispatch(selectPaymentTermData(tempPaymentTermList));
			dispatch(selectRegionData(tempRegionList));
			dispatch(selectFuelPriceData(tempFuelData));
			dispatch(selectCabinCrewData(tempCabinCrewRateList));
			dispatch(selectPilotCrewData(tempPilotCrewRateList));
			dispatch(selectFaPerAircraftData(tempFaPerAircraft));
			dispatch(selectPilotsPerAircraftData(tempPilotsPerAircraft));
			dispatch(selectAcquisitionPriceData(tempAcquisitionPrice));
			dispatch(selectInsuranceData(tempInsuranceData));
			dispatch(selectEconOrderID(selected.id));
		} else {
			// placeholder for demand data
			dispatch(selectDemandOrderID(selected.id));
		}

		switch (selected.package) {
			case "Performance":
				dispatch(chosePerformance());
				break;
			case "Economic":
				dispatch(choseEconomic());
				break;
			case "Demand":
				dispatch(choseDemand());
				break;
			default:
				break;
		}

		switch (selected.packageType) {
			case "Basic":
				dispatch(choseBasic());
				break;
			case "Advanced":
				dispatch(choseAdvanced());
				break;
			case "Premium":
				dispatch(chosePremium());
				break;
			default:
				break;
		}
	}, [fetchedData, selected, dispatch]);

	// useEffect(() => {
	//     console.log(selected);
	// }, [selected]);

	const dispatchImportedData = useCallback(() => {
		const dispatchAll = (data, dispatcher) => {
			Object.entries(data).forEach((value, index) => {
				if (
					value[1] !== "undefined" &&
					dispatcher[value[0]] !== undefined &&
					value[1] !== "uuid" &&
					value[1] !== null
				) {
					dispatch(dispatcher[value[0]](JSON.parse(value[1])));
				}
			});
		};

		if (importedData[0]) {
			switch (selected.package) {
				case "Performance":
					dispatchAll(importedData[0], dispatcherList.PerfDispatchers);
					dispatch(chosePerformance());
					break;
				case "Economic":
					dispatchAll(importedData[0], dispatcherList.EconDispatchers);
					dispatch(choseEconomic());
					break;
				case "Demand":
					dispatchAll(importedData[0], dispatcherList.DemandDispatchers);
					dispatch(choseDemand());
					break;
				default:
					break;
			}

			switch (selected.packageType) {
				case "Basic":
					dispatch(choseBasic());
					break;
				case "Advanced":
					dispatch(choseAdvanced());
					break;
				case "Premium":
					dispatch(chosePremium());
					break;
				default:
					break;
			}

			setTimeout(() => {
				setStatus("Finished");
			}, 50);
		}
	}, [dispatch, importedData, selected, setStatus]);

	useEffect(() => {
		if (status === "Finished") {
			navigate(`/forms/${selected.package}/`, {
				replace: true,
			});
		}
		if (Object.keys(fetchedData).length > 0 && status !== "Finished") {
			processData();
		}
		if (Object.keys(importedData).length > 0 && status !== "Finished") {
			processData();
			dispatchImportedData();
		}
		// console.log(fetchedData, importedData, "testing", status);
	}, [
		fetchedData,
		importedData,
		navigate,
		processData,
		dispatchImportedData,
		status,
		selected,
		setStatus,
	]);

	const handleError = () => {
		setStatus("Initializing content...");
		setTimeout(() => initializeOrder(), 100);

		navigate(`/forms/?order=${orderNumber ? orderNumber : selected.uuid}`, {
			replace: true,
		});
	};

	return (
		<motion.div
			key={status}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.4 }}
		>
			{status.split(" ")[0] === "ERROR" ? (
				<UtilityClass.Button
					text={status}
					icon={"refresh"}
					width={"full"}
					onClickCommand={() => {
						handleError();
					}}
				/>
			) : status !== "Previous packages detected..." ? (
				<div
					className={
						"flex flex-column gap-x-3 items-center justify-center w-full"
					}
				>
					<div className="flex justify-center">
						<UtilityClass.CustomText
							textSize="large"
							color="black"
							fontWeight="bold"
							content={status}
						/>
					</div>
					{status !==
						"No matching order found. If this was a mistake please contact support." && (
						<svg
							className="flex justify-center animate-spin"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
								className="spinner_P7sC"
							/>
						</svg>
					)}
				</div>
			) : (
				<div className="flex justify-center flex-col w-full">
					<UtilityClass.CustomText
						textSize="medium"
						color="black"
						fontWeight="bold"
						content={`Previously submitted orders have been detected for the email address: ${primaryOrder[0]?.email}`}
						alignment="center"
						noMargin
					/>
					<UtilityClass.CustomText
						textSize="medium"
						color="black"
						fontWeight="bold"
						content={`One of these orders may also be selected to import past values into a new order.`}
						alignment="center"
					/>
					<UtilityClass.OrderTable
						bottomMargin
						header={"New Orders"}
						selected={selected}
						current
						setSelected={setSelected}
						setSecondarySelected={setSecondarySelected}
						orderPackage={selected.package}
						orderType={selected.packageType}
						data={primaryOrder}
					/>
					{remainingOrders.length > 0 && (
						<UtilityClass.OrderTable
							selected={secondarySelected}
							header={"Previously Submitted Orders"}
							setSelected={setSecondarySelected}
							orderPackage={selected.package}
							orderType={selected.packageType}
							data={remainingOrders}
						/>
					)}
				</div>
			)}
		</motion.div>
	);
};

export default Fetcher;
