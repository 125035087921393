import React, { useEffect } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	selectEconAircraftInsurance,
	selectAircraftOwnershipChoice,
	selectLocation,
	selectAircraftAcquisition3,
} from "../../reducers/econDataSlice";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import LeaseRate3 from "./LeaseRate3";
import FinanceAssumptions3 from "./FinanceAssumptions3";

const FixedOperatingCost3 = (props) => {
	// This component is a multi step/page component that will render the various forms for economic numbers per AC (follow live forms)

	let location = useLocation();

	let data = useSelector((state) => state.econData);
	let acType3 = data.acType3;
	let aircraftInsuranceSelector = data.aircraftInsurance3;
	let insurance = data.aircraftInsurance3;
	let ownership = data.aircraftOwnership3;
	let acquisitionPrice = data.aircraftAcquisition3;
	let prevOwnership = data.aircraftOwnership2;
	let prevLocation = data.location;

	let insuranceData = data.insuranceData;
	let acquisitionData = data.acquisitionPriceData.find((value) => {
		if (acType3.length > 0) {
			let current = acType3.split(",");
			return (
				value.engineType === current[1].trim() &&
				value.aircraftType === current[0].trim()
			);
		} else {
			return true;
		}
	});

	const [defaultInsurance, setDefaultInsurance] = useState(
		insurance === undefined || insurance.length === 0 ? false : true
	);

	const [defaultOwnership, setDefaultOwnership] = useState(
		ownership === undefined || ownership.length === 0 ? false : true
	);

	const [defaultAcquisitionPrice, setDefaultAcquisitionPrice] = useState(
		acquisitionPrice === undefined || acquisitionPrice.length === 0
			? false
			: true
	);

	const dispatch = useDispatch();
	const [aircraftInsurance, setAircraftInsurance] = useState(insurance);
	const chooseAircraftInsurance = (v) => {
		setAircraftInsurance(v);
	};
	const [aircraftOwnership, setAircraftOwnership] = useState(ownership);
	const chooseAircraftOwnership = (v) => {
		setAircraftOwnership(v);
	};

	const [aircraftAcquisitionPrice, setAircraftAcquisitionPrice] =
		useState(acquisitionPrice);

	useEffect(() => {
		dispatch(selectEconAircraftInsurance(handleDispatchInsurance()));
	}, []);

	const dispatchInputs = () => {
		dispatch(selectLocation(location));
		dispatch(selectEconAircraftInsurance(handleDispatchInsurance()));
		dispatch(selectAircraftOwnershipChoice(handleDispatchOwnership()));
		dispatch(selectAircraftAcquisition3(aircraftAcquisitionPrice));
	};

	const handleDispatchInsurance = () => {
		return { aircraftNbr: 3, aircraftInsurance: aircraftInsurance };
	};

	const handleDispatchOwnership = () => {
		return { aircraftNbr: 3, aircraftOwnership: aircraftOwnership };
	};

	useEffect(() => {
		console.log(aircraftOwnership);
	});

	// redux calls
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);
	// THIS IS CALLING THE PERFORMANCE AC TYPES. NEED TO FIX THIS IN MERGE TO MAIN ========================================

	const validateContinue = () => {
		let validated = false;
		if (aircraftAcquisitionPrice != "" && aircraftInsurance != "") {
			validated = true;
		} else {
			validated = false;
		}
		return validated;
	};

	return (
		<motion.div>
			<UtilityClass.CustomText
				textSize="medium"
				fontWeight="semibold"
				color="grey"
				content={`${`Aircraft #3: ${acType3}`}`}
			/>
			<UtilityClass.CustomText
				textSize="title"
				fontWeight="semibold"
				color="black"
				content={"Fixed Operating Cost: Continued"}
			/>
			<UtilityClass.Separator />
			<div className="flex justify-center">
				<UtilityClass.RadioButtons
					title="Aircraft Ownership"
					text={["Lease", "Finance"]}
					row={true}
					value={chooseAircraftOwnership}
					horizontal={true}
					defaultValue={defaultOwnership ? ownership : "Finance"}
				/>
			</div>
			{aircraftOwnership == "Lease" ? (
				<>
					<LeaseRate3 aircraftOwnership={aircraftOwnership} />
				</>
			) : (
				<>
					<FinanceAssumptions3 aircraftOwnership={aircraftOwnership} />
				</>
			)}
		</motion.div>
	);
};

export default FixedOperatingCost3;
