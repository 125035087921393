import React, { useEffect, useState, useMemo } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useDispatch } from "react-redux";
import {
	selectACType2,
	selectACDefault2,
	chooseSecondPerfWeight,
	selectConfigRowsTwo,
	selectACList,
	selectConditionsTwo,
	selectOEM2,
} from "../../reducers/performanceDataSlice";
import {
	selectEconACType2,
	selectEconACDefault2,
	chooseSecondEconWeight,
	selectEconConfigRowsTwo,
	selectEconConditionsTwo,
	selectEconOEM2,
} from "../../reducers/econDataSlice";
import { useSelector } from "react-redux";
import { AircraftInfo } from "../../assets/aircraftInfo";
import { motion } from "framer-motion";
import CustomConfigWeights2 from "./CustomConfigWeights2";

const AircraftEngine2 = (props, ref) => {
	//this component needs a redux state that will be dynamic and will have the option at the
	//end to chose default values for ac and engine.
	//if default vals are chosen the "progressWidth" is passed up to parent component and is adjusted by +10
	//if custom is chosen it calls the customValues component and less "progressWidth"% is passed up to parent.
	// redux calls
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let packageType = useSelector((state) => state.packageType.value);

	let econData = useSelector((state) => state.econData);
	let perfData = useSelector((state) => state.performanceData);

	// console.log(econData);

	let data;
	if (packageType === "Performance") {
		data = perfData;
	} else {
		data = econData;
	}
	let OEMChoice = data.OEMChoice2;
	let acData = data.acType2;
	let OEMList = data.OEMList;
	let acDefault = data.acDefault2;
	let weightUnit = data.config.unit || "Kg";

	const [fetchedData, setFetchedData] = useState(
		data.configRowsTwo ? data.configRowsTwo : []
	);
	const [fetchedOptions, setFetchedOptions] = useState(
		data.acList ? data.acList : []
	);

	const processData = () => {
		const filteredChoice = data.acList.filter((value, index) => {
			if (value.manufacturer === data.OEMChoice2) {
				return value;
			}
		});
		setFetchedOptions(filteredChoice);
	};

	const processChartData = (aircraftType, engineType) => {
		const filteredChoice = fetchedOptions.filter((value, index) => {
			if (
				value.aircraftType === aircraftType &&
				value.engineType === engineType
			) {
				return value;
			}
		});
		setFetchedData(filteredChoice);
	};

	const matchAircraft = () => {
		if (acType != "" && OEMChoice) {
			let acSlice = acType.slice(0, 1);
			let choiceSlice = OEMChoice.slice(0, 1);
			if (OEMChoice == "MHI RJ" && acType != "") {
				return true;
			}
			if (acSlice === choiceSlice) {
				return true;
			} else {
				return false;
			}
		}
	};

	const conversion = (num, iunit) => {
		if (iunit === "Kg") {
			return (num / 2.2046).toFixed(0);
		} else if (iunit === "lbs") {
			return (num * 2.2046).toFixed(0);
		}
	};

	const dispatch = useDispatch();
	const [acType, setACType] = useState(acData); // to save the choice in a state in the parent component

	const chooseAircraft = (type) => {
		if (type != "") {
			setACType(type);
			let tempTypeEngine = type.split(",");
			processChartData(tempTypeEngine[0].trim(), tempTypeEngine[1].trim(), 0);
		} else {
			setACType("");
		}
	};

	const uniqueId = useMemo(
		() => `engine-${Math.random().toString(36).substring(2, 15)}`,
		[]
	);

	const [custom2, setCustom2] = useState("No, use default values."); // to save the choice in a state in the parent component
	const chooseCustom2 = (type) => {
		setCustom2(type);
		// console.log(type);
	};

	const [defaultAC, setDefaultAC] = useState(
		acData === undefined ? false : true
	);

	const [defaultOEM, setDefaultOEM] = useState(
		OEMChoice === undefined ? false : true
	);

	const [defaultChoice2, setDefaultChoice2] = useState(
		acDefault === undefined ? false : true
	);

	const generateListOptions = () => {
		let options = [];
		if (fetchedOptions.length > 0) {
			fetchedOptions.map((option) => {
				options.push(`${option.aircraftType}, ${option.engineType}`);
			});
		}
		return options;
	};

	const generateRow = () => {
		let rowData = [];
		if (fetchedData.length > 0) {
			fetchedData.map((info) => {
				rowData.push(info.aircraftType);
				rowData.push(info.engineType);
				rowData.push(info.configTotalSeats);
				rowData.push(
					`${info.maxTakeoffWeight} / ${(
						info.maxTakeoffWeight * 2.2046
					).toFixed(0)}`
				);
				rowData.push(
					`${info.maxLandingWeight} / ${(
						info.maxLandingWeight * 2.2046
					).toFixed(0)}`
				);
				rowData.push(
					`${info.operatingWeight} / ${(info.operatingWeight * 2.2046).toFixed(
						0
					)}`
				);
				rowData.push(
					`${info.maxPayload} / ${(info.maxPayload * 2.2046).toFixed(0)}`
				);
			});
		}
		return [rowData];
	};

	const dispatchInputs = () => {
		if (OEMChoice !== "Default" && acType && acType.length > 0) {
			let newConditions = { ...data.conditions };
			if (data.acType2 !== acType) {
				newConditions.divFixedMach = undefined;
				newConditions.fixedMach = undefined;
				newConditions.cruiseSpeed = "Long Range Cruise";
				newConditions.divCruiseSpeed = "Long Range Cruise";
			}

			let inputs = {
				seats: fetchedData[0].configTotalSeats,
				mtow:
					weightUnit === "lbs"
						? parseFloat(conversion(fetchedData[0].maxTakeoffWeight, "lbs"))
						: fetchedData[0].maxTakeoffWeight,
				mlw:
					weightUnit === "lbs"
						? parseFloat(conversion(fetchedData[0].maxLandingWeight, "lbs"))
						: fetchedData[0].maxLandingWeight,
				owe:
					weightUnit === "lbs"
						? parseFloat(conversion(fetchedData[0].operatingWeight, "lbs"))
						: fetchedData[0].operatingWeight,
				payload: fetchedData[0].maxPayload,
				unit: acDefault === "No, use default values." ? "Kg" : weightUnit,
			};

			if (packageType === "Performance") {
				dispatch(selectACType2(acType));
				dispatch(selectACDefault2(custom2));
				dispatch(chooseSecondPerfWeight(inputs));
				dispatch(selectConfigRowsTwo(fetchedData));
				dispatch(selectConditionsTwo(newConditions));
			} else if (packageType === "Economic") {
				dispatch(selectEconACType2(acType));
				dispatch(selectEconACDefault2(custom2));
				dispatch(chooseSecondEconWeight(inputs));
				dispatch(selectEconConfigRowsTwo(fetchedData));
				dispatch(selectEconConditionsTwo(newConditions));
			}
		}
	};

	useEffect(() => {
		processData();
	}, [OEMChoice]);

	useEffect(() => {
		dispatchInputs();
	}, [acType]);

	//validation based on inputs
	const validateContinue = () => {
		let validated = false;
		if (acType.length > 0) {
			validated = true;
		} else {
			validated = false;
		}

		console.log("validated inside aceng2: ", validated);
		return validated;
	};

	const [OEMSelected2, setOEM2] = useState("Airbus"); // to save the choice in a state in the parent component
	const chooseOEM2 = (type) => {
		// function called in the prop value
		setOEM2(type);
		// setACType([])
		if (packageType === "Performance") {
			dispatch(selectOEM2(type));
			dispatch(selectACDefault2("No, use default values."));
			dispatch(selectACType2([]));
			if (!acType) {
				dispatch(selectACType2([]));
			}
		} else if (packageType === "Economic") {
			dispatch(selectEconOEM2(type));
			dispatch(selectEconACDefault2("No, use default values."));
			dispatch(selectEconACType2([]));
			if (!acType) {
				dispatch(selectEconACType2([]));
			}
		}
	};
	useEffect(() => {
		setACType(acData.length > 0 ? acData : []);
	}, [OEMSelected2]);

	useEffect(() => {
		const isValid = validateContinue();
		props.onValidateChange(isValid);
	}, [OEMSelected2, acType]);

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.1 }}
		>
			<div className="flex-1 ">
				<UtilityClass.DropdownMenu
					title="Select Aircraft #2 OEM"
					titleBool={true}
					defaultVals={OEMChoice == "Default" ? null : OEMChoice}
					options={OEMList}
					isSelected={OEMChoice ? false : true}
					internalTitle="Please select an OEM"
					value={chooseOEM2}
					width="auto"
				/>
				{OEMChoice != "" && OEMChoice != "Default" ? (
					<div>
						<UtilityClass.DropdownMenu
							key={OEMSelected2}
							title="Select aircraft, engine combination to see default characteristics"
							titleBool={true}
							label="Please select an aircraft type"
							options={generateListOptions()}
							internalTitle="Please select an aircraft type"
							includeDefaults={defaultAC}
							value={chooseAircraft}
							defaultVals={
								matchAircraft() && data.acList.length > 0 && acData
									? acData
									: null
							}
							showTable={
								matchAircraft() && data.acList.length > 0 && acType != ""
							}
							headerList={[
								"ACType",
								"Engine",
								"Seats",
								"MTOW (KG/Lbs)",
								"MLW (KG/Lbs)",
								"OWE (KG/Lbs)",
								"Max Payload (KG/Lbs)",
							]}
							rows={generateRow()}
							width="auto"
						/>
						<UtilityClass.CustomText
							textSize="large"
							fontWeight="thin"
							color="black"
							content={
								"Default weight unit for analysis is Kg. For Lbs, select custom values below"
							}
						/>
						{acType != "" && acType.length > 0 && acData.length > 0 && (
							<UtilityClass.RadioButtons
								key={`${uniqueId}-${OEMSelected2}`}
								title="Would you like to provide custom values?"
								text={[
									"No, use default values.",
									"Yes, I will provide custom values.",
								]}
								value={chooseCustom2}
								width="fit"
								defaultValue={
									defaultChoice2 ? acDefault : "No, use default values."
								}
							/>
						)}
						{custom2 &&
						custom2 === "Yes, I will provide custom values." &&
						acData.length > 0 ? (
							<>
								<CustomConfigWeights2 key={`${acData}-${OEMSelected2}`} />
							</>
						) : (
							<></>
						)}
					</div>
				) : (
					<></>
				)}
			</div>
			{/* {custom && custom === "No, use default values." ? (
				<UtilityClass.BackNextButtons
					nextButtonText="NEXT"
					backLink={"/forms/" + packageType + "/OEM2"}
					nextLink={"/forms/" + packageType + "/SelectedAircraft"}
					validation={validateContinue()}
					dispatchInputs={dispatchInputs}
				/>
			) : (
				<UtilityClass.BackNextButtons
					nextButtonText="NEXT"
					backLink={"/forms/" + packageType + "/OEM2"}
					nextLink={"/forms/" + packageType + "/CustomConfigWeights2"}
					validation={validateContinue()}
					dispatchInputs={dispatchInputs}
				/>
			)} */}
		</motion.div>
	);
};

export default AircraftEngine2;
