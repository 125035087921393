import React, { useEffect, useRef } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	selectCrewLoading1,
	selectLocation,
} from "../../reducers/econDataSlice";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import CrewLoading2 from "./CrewLoading2";
import CrewLoading3 from "./CrewLoading3";

const CrewLoading = (props) => {
	// This component is an extension of entering the economic numbers from FixedOperatingCost but can be called from 2 options
	// (1. CostAssumptions OR 2. Economic main component ** need to see what will be better** )

	let location = useLocation();
	let econData = useSelector((state) => state.econData);

	// redux calls
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let acType1 = econData.acType;

	// 3000 is the default value
	let aircraftUtil = econData.conditions.aircraftUtil ?? 3000;
	let aircraftUtil2 = econData.conditions.aircraftUtil2 ?? 3000;

	let faData = [{ data: [], header: ["Please select"] }];
	const totalFA = () => {
		let faDataTemp = [];
		for (let i = 0; i < 3; i++) {
			faDataTemp.push([Math.floor(econData.config.seats / 50) + i, ""]);
		}
		faData[0].data = faDataTemp;
	};
	totalFA();

	const dispatch = useDispatch();

	let flightCrewLoading = econData.crewLoading1.flightCrewLoading;
	const [pilotSalary, setPilotSalary] = useState(flightCrewLoading.pilotSalary);
	const [coPilotSalary, setCoPilotSalary] = useState(
		flightCrewLoading.coPilotSalary
	);
	const [number, setNumber] = useState(flightCrewLoading.number);
	const [pilotSalaryYearlyBenefit, setPilotSalaryYearlyBenefit] = useState(
		flightCrewLoading.pilotYearlyBenefit
	);
	const [coPilotSalaryYearlyBenefit, setCoPilotSalaryYearlyBenefit] = useState(
		flightCrewLoading.coPilotYearlyBenefit
	);

	let cabinCrewLoading = econData.crewLoading1.cabinCrewLoading;
	const [cabinNumber, setCabinNumber] = useState(cabinCrewLoading.number);
	const [faPerAircraft, setFaPerAircraft] = useState(
		cabinCrewLoading.faPerAircraft
	);
	const [faSalaryPerYear, setFaSalaryPerYear] = useState(
		cabinCrewLoading.faSalaryPerYear
	);
	const [faYearlyBenefit, setFaYearlyBenefit] = useState(
		cabinCrewLoading.faYearlyBenefit
	);
	const [combinedLoading, setCombinedLoading] = useState(0);
	const [totalPilotCostPerBH, setTotalPilotCostPerBH] = useState("");
	const [totalCoPilotCostPerBH, setTotalCoPilotCostPerBH] = useState("");
	const [totalFACostPerBH, setTotalFACostPerBH] = useState("");

	const crewLoading2Ref = useRef();
	const crewLoading3Ref = useRef();

	const dispatchInputs = () => {
		dispatch(selectLocation(location));

		if (crewLoading2Ref.current && crewLoading2Ref.current.dispatchInputs) {
			crewLoading2Ref.current.dispatchInputs();
		}
		if (crewLoading3Ref.current && crewLoading3Ref.current.dispatchInputs) {
			crewLoading3Ref.current.dispatchInputs();
		}

		let initInputs = {
			flightCrewLoading: {
				number: number,
				pilotSalary: pilotSalary,
				pilotYearlyBenefit: pilotSalaryYearlyBenefit,
				coPilotSalary: coPilotSalary,
				coPilotYearlyBenefit: coPilotSalaryYearlyBenefit,
			},
			cabinCrewLoading: {
				number: cabinNumber,
				faPerAircraft: faPerAircraft,
				faSalaryPerYear: faSalaryPerYear,
				faYearlyBenefit: faYearlyBenefit,
			},
			bhCosts: {
				pilotBH: totalPilotCostPerBH,
				coPilotBH: totalCoPilotCostPerBH,
				faBH: totalFACostPerBH,
			},
		};
		dispatch(selectCrewLoading1(initInputs));
	};

	useEffect(() => {
		let initInputs = {
			flightCrewLoading: {
				number: number,
				pilotSalary: pilotSalary,
				pilotYearlyBenefit: pilotSalaryYearlyBenefit,
				coPilotSalary: coPilotSalary,
				coPilotYearlyBenefit: coPilotSalaryYearlyBenefit,
			},
			cabinCrewLoading: {
				number: cabinNumber,
				faPerAircraft: faPerAircraft,
				faSalaryPerYear: faSalaryPerYear,
				faYearlyBenefit: faYearlyBenefit,
			},
			bhCosts: {
				pilotBH: totalPilotCostPerBH,
				coPilotBH: totalCoPilotCostPerBH,
				faBH: totalFACostPerBH,
			},
		};
		dispatch(selectCrewLoading1(initInputs));
	}, []);
	console.log(faYearlyBenefit);
	useEffect(() => {
		try {
			let costPerBH =
				(parseFloat(pilotSalary) *
					(1 + parseFloat(pilotSalaryYearlyBenefit / 100)) *
					parseFloat(number)) /
				parseFloat(aircraftUtil);

			setTotalPilotCostPerBH(
				(isNaN(costPerBH) ? 0 : costPerBH).toFixed(2).toString()
			);

			let coPilotCostPerBH =
				(parseFloat(coPilotSalary) *
					(1 + parseFloat(coPilotSalaryYearlyBenefit / 100)) *
					parseFloat(number)) /
				parseFloat(aircraftUtil);
			setTotalCoPilotCostPerBH(
				(isNaN(coPilotCostPerBH) ? 0 : coPilotCostPerBH).toFixed(2).toString()
			);

			let FACostPerBH =
				(parseFloat(faSalaryPerYear) *
					(1 + parseFloat(faYearlyBenefit / 100)) *
					parseFloat(cabinNumber) *
					parseFloat(faPerAircraft)) /
				parseFloat(aircraftUtil);
			setTotalFACostPerBH(
				(isNaN(FACostPerBH) ? 0 : FACostPerBH).toFixed(2).toString()
			);

			setCombinedLoading(
				parseFloat(totalPilotCostPerBH) +
					parseFloat(totalCoPilotCostPerBH) +
					parseFloat(totalFACostPerBH)
			);
		} catch (error) {}
	}, [
		aircraftUtil,
		aircraftUtil2,
		totalCoPilotCostPerBH,
		totalPilotCostPerBH,
		totalFACostPerBH,
		pilotSalary,
		coPilotSalary,
		number,
		pilotSalaryYearlyBenefit,
		coPilotSalaryYearlyBenefit,
		cabinCrewLoading,
		cabinNumber,
		faPerAircraft,
		faSalaryPerYear,
		faYearlyBenefit,
	]);

	const validateContinue = () => {
		let validated = false;
		if (
			pilotSalary != "" &&
			coPilotSalary != "" &&
			faSalaryPerYear != "" &&
			faPerAircraft != ""
		) {
			validated = true;
		} else {
			validated = false;
		}
		return validated;
	};

	return (
		<motion.div>
			<UtilityClass.CustomText
				textSize="subtitle"
				fontWeight="semibold"
				color="black"
				content={"Crew Loading"}
			/>
			<UtilityClass.Separator />
			<div
				className={`${
					packageLevel !== "Basic"
						? "flex flex-wrap flex-row items-start select-none mx-0.5 "
						: ""
				}`}
			>
				<div className="flex-1 mx-0.5">
					<UtilityClass.CustomText
						textSize="medium"
						fontWeight="semibold"
						color="grey"
						content={`${
							packageLevel === "Basic"
								? `Aircraft: ${acType1}`
								: `Aircraft #1: ${acType1}`
						}`}
					/>

					<div className="flex justify-center h-full">
						<div className={"flex flex-row w-full gap-4 justify-center"}>
							<div className="flex flex-col my-auto gap-3">
								<UtilityClass.RangeSlider
									noPadding
									numID="7"
									text="Flight Crew Loading"
									min={"3.5"}
									max={"6.5"}
									maxWidth={"xs"}
									defaultValue={"4.5"}
									step={"0.5"}
									unit=" "
									value={setNumber}
									toolTip={true}
									toolTipDesc={"Number of Crews Per Aircraft"}
									includeDefaults={number !== undefined}
									defaultVals={flightCrewLoading.number}
								/>
								<UtilityClass.DefaultInput
									combinedDropdown={[true, [], false]}
									labelBool={true}
									leftSquareLabelBool={true}
									leftSquareLabelText={"USD"}
									label="Pilot Salary"
									placeholder={"Pilot Salary"}
									rightSquareLabelBool={true}
									rightSquareLabelText={"Per Year"}
									width="full"
									value={setPilotSalary}
									includeDefaults={pilotSalary !== undefined}
									defaultVals={flightCrewLoading.pilotSalary}
									defaultValue={flightCrewLoading.pilotSalary}
									regex={/^(0|[1-9][0-9]*)?$/}
								/>
								<UtilityClass.RangeSlider
									numID="8"
									noPadding
									text="Pilot Yearly Benefit (% of Salary)"
									min={"10"}
									max={"50"}
									maxWidth={"xs"}
									defaultValue={"25"}
									step={"5"}
									unit="%"
									value={setPilotSalaryYearlyBenefit}
									includeDefaults={pilotSalaryYearlyBenefit !== undefined}
									defaultVals={flightCrewLoading.pilotYearlyBenefit}
								/>
								<UtilityClass.DefaultInput
									combinedDropdown={[true, [], false]}
									labelBool={true}
									leftSquareLabelBool={true}
									leftSquareLabelText={"USD"}
									label="Co-Pilot Salary"
									placeholder={"Co-Pilot Salary"}
									rightSquareLabelBool={true}
									rightSquareLabelText={"Per Year"}
									width="full"
									value={setCoPilotSalary}
									includeDefaults={coPilotSalary !== undefined}
									defaultVals={flightCrewLoading.coPilotSalary}
									defaultValue={flightCrewLoading.coPilotSalary}
									regex={/^(0|[1-9][0-9]*)?$/}
								/>
								<UtilityClass.RangeSlider
									numID="10"
									text="Co-Pilot Yearly Benefit (% of Salary)"
									min={"10"}
									noPadding
									maxWidth={"xs"}
									max={"50"}
									defaultValue={"25"}
									step={"5"}
									unit="%"
									value={setCoPilotSalaryYearlyBenefit}
									includeDefaults={coPilotSalaryYearlyBenefit !== undefined}
									defaultVals={flightCrewLoading.coPilotYearlyBenefit}
								/>
							</div>

							<div className="flex flex-col my-auto mt-0 gap-3">
								<UtilityClass.RangeSlider
									noPadding
									numID="12"
									text="Cabin Crew Loading"
									min={"3.5"}
									maxWidth={"xs"}
									max={"6.5"}
									defaultValue={"4.5"}
									step={"0.5"}
									unit=" "
									value={setCabinNumber}
									toolTip={true}
									toolTipDesc={"Number of Crews Per Aircraft"}
									includeDefaults={cabinNumber !== undefined}
									defaultVals={cabinCrewLoading.number}
								/>
								<UtilityClass.DefaultInput
									combinedDropdown={[true, [], false]}
									labelBool={true}
									leftSquareLabelBool={true}
									leftSquareLabelText={"USD"}
									label="Flight Attendant Salary"
									placeholder={"F/A Salary"}
									rightSquareLabelBool={true}
									rightSquareLabelText={"Per Year"}
									width="full"
									value={setFaSalaryPerYear}
									includeDefaults={faSalaryPerYear !== undefined}
									defaultVals={cabinCrewLoading.faSalaryPerYear}
									defaultValue={cabinCrewLoading.faSalaryPerYear}
									regex={/^(0|[1-9][0-9]*)?$/}
								/>
								<UtilityClass.RangeSlider
									numID="13"
									text="F/A Yearly Benefit (% of Salary)"
									min={"10"}
									maxWidth={"xs"}
									noPadding
									max={"50"}
									defaultValue={"25"}
									step={"5"}
									unit="%"
									value={setFaYearlyBenefit}
									includeDefaults={faYearlyBenefit !== undefined}
									defaultVals={cabinCrewLoading.faYearlyBenefit}
								/>
								<UtilityClass.DefaultInput
									combinedDropdown={[true, faData[0]]}
									labelBool={true}
									placeholder={"Number"}
									rightSquareLabelBool={true}
									label="F/A Per Aircraft"
									rightSquareLabelText={"F/A Per Aircraft"}
									width="full"
									value={setFaPerAircraft}
									includeDefaults={faPerAircraft !== undefined}
									defaultVals={cabinCrewLoading.faPerAircraft}
									defaultValue={cabinCrewLoading.faPerAircraft}
									regex={/^(0|[1-9]|1[0-9]|20)?$/}
								/>
								<div className="invisible flex">
									<UtilityClass.RangeSlider
										numID="invalid"
										text="Co-Pilot Yearly Benefit (% of Salary)"
										min={"10"}
										noPadding
										max={"50"}
										defaultValue={"25"}
										step={"5"}
										unit="%"
										value={() => {
											return;
										}}
										includeDefaults={false}
										defaultVals={[]}
									/>
								</div>
							</div>
						</div>
					</div>
					<div
						className={"flex mx-auto justify-center align-middle w-7/12 py-3"}
					>
						<div className="flex flex-col justify-center w-1/2">
							<UtilityClass.CustomText
								textSize="large"
								fontWeight="semibold"
								noMargin
								color="black"
								content={`$${totalPilotCostPerBH} \u00A0\u00A0 + \u00A0\u00A0 $${totalCoPilotCostPerBH} + \u00A0\u00A0 $${totalFACostPerBH}`}
							/>
							<div className="pb-5">
								<UtilityClass.CustomText
									textSize="medium"
									fontWeight="semibold"
									noMargin
									color="black"
									content={`(Pilot Cost Per BH \u00A0\u00A0 + \u00A0\u00A0 Co-Pilot Cost Per BH + \u00A0\u00A0 FA Cost Per BH)`}
								/>
							</div>

							<UtilityClass.CustomText
								textSize="large"
								noMargin
								fontWeight="bold"
								color="black"
								content={`\u00A0\u00A0 = \u00A0 $${combinedLoading.toFixed(
									2
								)} USD Per BH`}
							/>
						</div>
					</div>
				</div>
				{(packageLevel === "Advanced" || packageLevel === "Premium") &&
					econData.numberAircraft > 1 && (
						<div className="flex-1 mx-0.5">
							<CrewLoading2 ref={crewLoading2Ref} />
						</div>
					)}
				{packageLevel === "Premium" && econData.numberAircraft > 2 && (
					<div className="flex-1 mx-0.5">
						<CrewLoading3 ref={crewLoading3Ref} />
					</div>
				)}
			</div>
			<UtilityClass.BackNextButtons
				nextButtonText="NEXT"
				backLink={"/forms/Economic/CashOperatingCosts"}
				nextLink={"/forms/" + packageType + "/AirportAndNavigationCharges"}
				dispatchInputs={dispatchInputs}
				validation={validateContinue()}
			/>
		</motion.div>
	);
};

export default CrewLoading;
