import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	value: "Default",
};

export const packageLevelSlice = createSlice({
	name: "packageLevel",
	initialState,
	reducers: {
		choseBasic: (state) => {
			state.value = "Basic";
			// console.log(state.value);
		},
		choseAdvanced: (state) => {
			state.value = "Advanced";
			// console.log(state.value);
		},
		chosePremium: (state) => {
			state.value = "Premium";
			// console.log(state.value);
		},
		resetLevel: (state) => {
			state.value = "Default";
			// console.log(state.value);
		},
	},
});

export const { choseBasic, choseAdvanced, chosePremium, resetLevel } =
	packageLevelSlice.actions;
export default packageLevelSlice.reducer;
