import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	orderNumber: 0,
	orderID: 0,
	logoUpload: {
		logoChoice: "No",
		logoImage: undefined,
		resultChoice: "No",
	},
	submission: {
		firstName: "",
		lastName: "",
		company: "",
		phone: "",
		email: "",
	},
	airline: "",
	scheduledStartDate: "",
	customScheduleChoice: "false",
	markets: Array(5).fill(Array(5).fill("")),
	schedule: [
		Array(5).fill(Array(3).fill("")),
		Array(5).fill(Array(7).fill("")),
		Array(5 * 3).fill(Array(4).fill("")),
	],
};

export const demandDataSlice = createSlice({
	name: "demandData",
	initialState,
	reducers: {
		selectDemandOrderNumber: (state, arrayData) => {
			state.orderNumber = arrayData.payload;
			// console.log(state.airline);
		},
		selectDemandOrderID: (state, arrayData) => {
			state.orderID = arrayData.payload;
			// console.log(state.fetched);
		},
		setDemandLogoUpload: (state, arrayData) => {
			state.logoUpload = arrayData.payload;
			// console.log(state.airline);
		},
		setDemandSubmission: (state, arrayData) => {
			state.submission = arrayData.payload;
			// console.log(state.fetched);
		},
		setAirline: (state, arrayData) => {
			state.airline = arrayData.payload;
			// console.log(state.airline);
		},
		setScheduledStartDate: (state, arrayData) => {
			state.scheduledStartDate = arrayData.payload;
			// console.log(state.scheduledStartDate);
		},
		setCustomScheduleChoice: (state, arrayData) => {
			state.customScheduleChoice = arrayData.payload;
			// console.log(state.scheduledStartDate);
		},
		setMarkets: (state, arrayData) => {
			state.markets = arrayData.payload;
			// console.log(state.markets);
		},
		setSchedule: (state, arrayData) => {
			state.schedule = arrayData.payload;
			// console.log(state.schedule);
		},
		resetDemand: (state) => {
			state.orderNumber = 0;
			state.orderID = 0;
			state.logoUpload = {
				logoChoice: "No",
				logoImage: undefined,
				resultChoice: "No",
			};
			state.submission = {
				firstName: "",
				lastName: "",
				company: "",
				phone: "",
				email: "",
			};
			state.airline = "";
			state.scheduledStartDate = "";
			state.customScheduleChoice = "";
			state.markets = Array(5).fill(Array(5).fill(""));
			state.schedule = [
				Array(5).fill(Array(5).fill("")),
				Array(5).fill(Array(7).fill("")),
				Array(5 * 3).fill(Array(4).fill("")),
			];
			// console.log(
			//     state.airline,
			//     state.scheduledStartDate,
			//     state.markets,
			//     state.schedule
			// );
		},
	},
});

export const {
	setAirline,
	selectDemandOrderNumber,
	setScheduledStartDate,
	setCustomScheduleChoice,
	selectDemandOrderID,
	setMarkets,
	setDemandLogoUpload,
	setSchedule,
	resetDemand,
	setDemandSubmission,
} = demandDataSlice.actions;
export default demandDataSlice.reducer;
