import { Outlet, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProgressCount } from "../util/progress";
import { demandPaths, econPaths, perfPaths } from "../routes/paths";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AviAnalysisDarkLogo from "../images/logo-dark.png";
import NotFound from "../components/commonAll/NotFound";
import NavMenu from "../components/commonAll/NavMenu";

const DefaultLayout = () => {
	const location = useLocation();
	const validSession = useSelector((state) => state.validSession.validSession);
	const dispatch = useDispatch();

	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);

	let desc;
	let paths;
	switch (packageType) {
		case "Performance":
			desc =
				packageLevel === "Basic"
					? "Basic Level - 1 Aircraft Type and Up to 10 Routes"
					: packageLevel === "Advanced"
					? "Advanced Level - 2 Aircraft Types and Up to 15 Routes"
					: "Premium Level - 3 Aircraft Types and Up to 20 Routes";
			paths = perfPaths(packageLevel);
			break;
		case "Economic":
			desc =
				packageLevel === "Basic"
					? "Basic Level - 1 Aircraft Type and Up to 10 Routes"
					: packageLevel === "Advanced"
					? "Advanced Level - 2 Aircraft Types and Up to 15 Routes"
					: "Premium Level - 3 Aircraft Types and Up to 20 Routes";
			paths = econPaths(packageLevel);
			break;
		case "Demand":
			desc = `${packageLevel} Level - Up to ${
				packageLevel === "Premium" ? "5" : "3"
			} Markets`;
			paths = demandPaths(packageLevel);
			break;
		default:
			paths = [""];
			break;
	}

	// let orderNumber = useSelector((state) => {
	//     switch (packageType) {
	//         case "Performance":
	//             return state.performanceData.orderNumber;
	//         case "Economic":
	//             return state.econData.orderNumber;
	//         case "Demand":
	//             return state.demandData.orderNumber;
	//         default:
	//             return "Unspecified";
	//     }
	// });

	const [progressWidth, setProgressWidth] = useState(0);
	const [count, setCount] = useState([0, 0]);

	useEffect(() => {
		getProgressCount(paths, location, setProgressWidth, setCount, packageType);
	}, [location]);

	// useEffect(() => {
	//     if (
	//         orderNumber === "0" ||
	//         orderNumber === 0 ||
	//         orderNumber === "Unspecified"
	//     ) {
	//         dispatch(setValidSession(false));
	//     } else {
	//         dispatch(setValidSession(true));
	//     }
	// }, [orderNumber, packageType, dispatch, validSession]);
	return (
		<>
			{location.pathname === "/" ? (
				<NotFound />
			) : (
				<div className="px-2 w-full">
					<div className="fixed left-0 top-0 p-3 z-30">
						<a
							href="https://dashboard.avpforms.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span className="sr-only"></span>
							<img
								className="h-8 w-auto sm:h-10 select-none"
								src={AviAnalysisDarkLogo}
								alt="AviAnalysis Logo"
							/>
						</a>
					</div>
					{location.pathname !== "/forms/" &&
						location.pathname !== "/" &&
						validSession && (
							<>
								<Header
									title={packageType}
									description={desc}
									progressWidth={`${progressWidth}%`}
									count={count}
								/>
								{/* <NavMenu paths={paths} /> */}
							</>
						)}
					<main className="pt-2 pb-6">
						<Outlet />
					</main>

					<div className="fixed bottom-0 w-full p-3 z-30">
						<Footer />
					</div>
				</div>
			)}
		</>
	);
};

export default DefaultLayout;
