import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { AircraftInfo } from "../../assets/aircraftInfo";
import { chooseFirstPerfWeight } from "../../reducers/performanceDataSlice";
import { chooseFirstEconWeight } from "../../reducers/econDataSlice";
import { motion } from "framer-motion";

const CustomConfigWeights = () => {
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let econData = useSelector((state) => state.econData);
	let perfData = useSelector((state) => state.performanceData);
	let data;

	let actype1 = "";
	if (packageType === "Performance") {
		data = perfData;
		actype1 = data.acType;
	} else {
		data = econData;
		actype1 = econData.acType;
	}
	let weightData = data.config;

	const matchInfo = () => {
		let match = "";
		let OEMNames = Object.keys(AircraftInfo);
		OEMNames.forEach((name) => {
			AircraftInfo[name].forEach((value) => {
				if (actype1 === value.acID) {
					match = value;
				}
			});
		});
		return match;
	};

	const info = matchInfo();

	let weightInputs = {
		seats: info["Seats"],
		mtow: info["MTOW (KG/Lbs)"],
		mlw: info["MLW (KG/Lbs)"],
		owe: info["OWE (KG/Lbs)"],
		unit: data.config.unit || "Kg",
	};

	const [seatsInput, setSeatsInput] = useState();
	const changeSeatsInput = (i) => {
		setSeatsInput(i);
	};
	const [mtowInput, setMtowInput] = useState();
	const changeMtowInput = (i) => {
		setMtowInput(i);
	};

	const [mlwInput, setMlwInput] = useState();
	const changeMlwInput = (i) => {
		setMlwInput(i);
	};

	const [oweInput, setOweInput] = useState();
	const changeOweInput = (i) => {
		setOweInput(i);
	};

	const [custom, setCustom] = useState(data.config.unit); // to save the choice in a state in the parent component
	const chooseCustom = (type) => {
		setCustom(type);
	};

	const conversion = (num, iunit) => {
		if (iunit === "Kg") {
			return (num / 2.2046).toFixed(0);
		} else if (iunit === "lbs") {
			return (num * 2.2046).toFixed(0);
		} else {
			return num;
		}
	};

	const convertUnits = (unit) => {
		weightInputs.seats = seatsInput;
		weightInputs.mtow = parseFloat(conversion(mtowInput, unit));
		weightInputs.mlw = parseFloat(conversion(mlwInput, unit));
		weightInputs.owe = parseFloat(conversion(oweInput, unit));
		weightInputs.unit = custom;
		if (packageType === "Performance") {
			dispatch(chooseFirstPerfWeight(weightInputs));
		} else if (packageType === "Economic") {
			dispatch(chooseFirstEconWeight(weightInputs));
		}
	};

	useEffect(() => {
		if (custom) {
			convertUnits(custom);
		}
	}, [custom]);

	useEffect(() => {
		initDispatch();
	}, []);

	const dispatch = useDispatch();

	const initDispatch = () => {
		let initInputs = {
			seats: weightData.seats,
			mtow: weightData.mtow,
			mlw: weightData.mlw,
			owe: weightData.owe,
			unit: custom,
		};
		if (packageType === "Performance") {
			dispatch(chooseFirstPerfWeight(initInputs));
		} else if (packageType === "Economic") {
			dispatch(chooseFirstEconWeight(initInputs));
		}
	};

	const dispatchInputs = () => {
		if (seatsInput === undefined || seatsInput === "") {
			weightInputs.seats = data.config.seats;
		} else {
			weightInputs.seats = seatsInput;
		}
		if (mlwInput === undefined || mlwInput === "") {
			weightInputs.mlw = data.config.mlw;
		} else {
			weightInputs.mlw = mlwInput;
		}
		if (mtowInput === undefined || mtowInput === "") {
			weightInputs.mtow = data.config.mtow;
		} else {
			weightInputs.mtow = mtowInput;
		}
		if (oweInput === undefined || oweInput === "") {
			weightInputs.owe = data.config.owe;
		} else {
			weightInputs.owe = oweInput;
		}
		weightInputs.unit = custom;
		if (packageType === "Performance") {
			dispatch(chooseFirstPerfWeight(weightInputs));
		} else if (packageType === "Economic") {
			dispatch(chooseFirstEconWeight(weightInputs));
		}
	};

	const validateContinue = () => {
		let validated = false;
		if (
			seatsInput?.toString().length > 0 &&
			mtowInput?.toString().length > 0 &&
			mlwInput?.toString().length > 0 &&
			oweInput?.toString().length > 0
		) {
			validated = true;
		} else {
			validated = false;
		}
		return validated;
	};

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.1 }}
		>
			<div className="flex justify-center flex-col mx-auto">
				<div className="lg:w-6/12 md:w-10/12 w-6/12 mx-auto">
					{packageLevel === "Basic" ? (
						<UtilityClass.CustomText
							textSize="subtitle"
							fontWeight="semibold"
							color="black"
							content={"Custom configuration and weights"}
						/>
					) : (
						<UtilityClass.CustomText
							textSize="subtitle"
							fontWeight="semibold"
							color="black"
							content={"Custom configuration and weights for aircraft #1"}
						/>
					)}
					<UtilityClass.Separator />
					<UtilityClass.CustomText
						textSize="large"
						fontWeight="semibold"
						color="black"
						content={`Aircraft type: ${actype1}`}
					/>
					<UtilityClass.Separator />
					<UtilityClass.CustomText
						textSize="small"
						fontWeight="medium"
						color="grey"
						content={
							"Fields below are auto-filled with default values, if required, override with custom values"
						}
					/>
				</div>
			</div>
			{/* Seats */}
			<div className="w-6/12 flex justify-center flex-col mx-auto">
				<div className="max-w-xs mx-auto">
					<UtilityClass.DefaultInput
						defaultValue={data.config.seats}
						labelBool={true}
						label="Seats"
						placeholder={"Number of Seats"}
						value={changeSeatsInput}
						width="full"
						regex={/^(?:[1-9]\d*|)$/}
					/>
				</div>
				<div className="flex justify-center">
					<UtilityClass.RadioButtons
						title="Select weight unit"
						text={["Kg", "lbs"]}
						row={true}
						defaultValue={data.config.unit ? data.config.unit : "Kg"}
						value={chooseCustom}
						width="2/12"
					/>
				</div>
			</div>
			<div className="w-6/12 flex justify-center flex-col mx-auto">
				{/* MTOW */}
				<div className="max-w-xs mx-auto">
					<UtilityClass.DefaultInput
						defaultValue={data.config.mtow}
						labelBool={true}
						label="MTOW"
						value={changeMtowInput}
						width="full"
						labelBottomMarginBool={false}
						regex={/^(?:[1-9]\d*|)$/}
					/>
					{/* MLW */}
					<UtilityClass.DefaultInput
						defaultValue={data.config.mlw}
						labelBool={true}
						label="MLW"
						value={changeMlwInput}
						width="full"
						regex={/^(?:[1-9]\d*|)$/}
					/>
					{/* OWE */}
					<UtilityClass.DefaultInput
						defaultValue={data.config.owe}
						labelBool={true}
						label="OWE"
						value={changeOweInput}
						width="full"
						regex={/^(?:[1-9]\d*|)$/}
					/>
				</div>

				{/* <UtilityClass.BackNextButtons
					nextButtonText="NEXT"
					backLink={"/forms/" + packageType + "/AircraftEngine"}
					nextLink={
						packageLevel === "Premium"
							? "/forms/" + packageType + "/OEM2"
							: "/forms/" + packageType + "/RoutesAndDistances"
					}
					dispatchInputs={() => dispatchInputs()}
					validation={validateContinue()}
				/> */}
			</div>
		</motion.div>
	);
};

export default CustomConfigWeights;
