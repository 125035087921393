import { useEffect } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	selectEconAircraftInsurance,
	selectAircraftOwnershipChoice,
	selectEconLeaseRate,
	selectFinanceAssumptions,
	selectLocation,
} from "../../reducers/econDataSlice";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

const FinanceAssumptions = (props) => {
	const { aircraftOwnership } = props;
	// Gets rendered when user selects "Lease" option in Fixed Operating Cost component

	//redux calls
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let acType1 = useSelector((state) => state.econData.acType);
	let econData = useSelector((state) => state.econData);
	let numberAircraft = useSelector((state) => state.econData.numberAircraft);

	let location = useLocation();

	let aircraftData = econData.financeAssumptions1.aircraftPrice;
	let downPaymentData = econData.financeAssumptions1.downPayment;
	let interestData = econData.financeAssumptions1.interestRate;
	let paymentData = econData.financeAssumptions1.paymentTerm;
	let marketValue = econData.financeAssumptions1.marketValue;

	let aircraftInsuranceSelector = econData.aircraftInsurance1;
	let insurance = econData.aircraftInsurance1;
	let insuranceData = econData.insuranceData;

	let acquisitionPrice = econData.aircraftAcquisition;
	let ownership = econData.aircraftOwnership1;

	let acquisitionData = econData.acquisitionPriceData.find((value) => {
		if (acType1.length > 0) {
			let current = acType1.split(",");
			return (
				value.engineType === current[1].trim() &&
				value.aircraftType === current[0].trim()
			);
		} else {
			return true;
		}
	});

	let filterAircraft = (data) => {
		return data.find((value) => {
			if (acType1.length > 0) {
				let current = acType1.split(",");
				return (
					value.engineType === current[1].trim() &&
					value.aircraftType === current[0].trim()
				);
			} else {
				return true;
			}
		});
	};

	let marketList = filterAircraft(econData.marketData);
	let downPaymentList = filterAircraft(econData.downPaymentData);
	let interestRateList = filterAircraft(econData.interestRateData);
	let paymentTermList = filterAircraft(econData.paymentTermData);

	const [defaultInsurance, setDefaultInsurance] = useState(
		insurance === undefined || insurance.length === 0 ? false : true
	);

	const [defaultAircraft, setDefaultAircraft] = useState(
		aircraftData === undefined || aircraftData.length === 0 ? false : true
	);
	const [defaultDownPayment, setDefaultDownPayment] = useState(
		downPaymentData === undefined || downPaymentData.length === 0 ? false : true
	);
	const [defaultInterest, setDefaultInterest] = useState(
		interestData === undefined || interestData.length === 0 ? false : true
	);
	const [defaultPayment, setDefaultPayment] = useState(
		paymentData === undefined || paymentData.length === 0 ? false : true
	);
	const [defaultMarketValue, setDefaultMarketValue] = useState(
		marketValue === undefined || marketValue.length === 0 ? false : true
	);

	const [defaultAcquisitionPrice, setDefaultAcquisitionPrice] = useState(
		acquisitionPrice === undefined || acquisitionPrice.length === 0
			? false
			: true
	);

	const dispatch = useDispatch();
	const [aircraftPrice, setAircraftPrice] = useState();
	const chooseAircraftPrice = (v) => {
		setAircraftPrice(v);
	};
	const [downPayment, setDownPayment] = useState();
	const chooseDownPayment = (v) => {
		setDownPayment(v);
	};
	const [interestRate, setInterestRate] = useState();
	const chooseInterestRate = (v) => {
		setInterestRate(v);
	};
	const [paymentTerm, setPaymentTerm] = useState();
	const choosePaymentTerm = (v) => {
		setPaymentTerm(v);
	};

	const [aircraftInsurance, setAircraftInsurance] = useState(insurance);
	const chooseAircraftInsurance = (v) => {
		setAircraftInsurance(v);
	};

	const [aircraftAcquisitionPrice, setAircraftAcquisitionPrice] =
		useState(acquisitionPrice);

	const [marketValuePrice, setMarketValuePrice] = useState(acquisitionPrice);

	useEffect(() => {
		dispatch(selectEconAircraftInsurance(handleDispatchInsurance()));
	}, []);

	const dispatchInputs = () => {
		dispatch(selectLocation(location));
		dispatch(selectFinanceAssumptions(handleDispatch()));
		dispatch(selectEconLeaseRate({ aircraftNbr: 1, leaseRate: undefined }));
		dispatch(selectEconAircraftInsurance(handleDispatchInsurance()));
		dispatch(selectAircraftOwnershipChoice(handleDispatchOwnership()));
	};

	const handleDispatchInsurance = () => {
		return { aircraftNbr: 1, aircraftInsurance: aircraftInsurance };
	};

	const handleDispatchOwnership = () => {
		return { aircraftNbr: 1, aircraftOwnership: aircraftOwnership };
	};

	const handleDispatch = () => {
		return {
			aircraftNbr: 1,
			data: {
				aircraftPrice: aircraftPrice,
				downPayment: downPayment,
				interestRate: interestRate,
				paymentTerm: paymentTerm,
				marketValue: marketValuePrice,
			},
		};
	};

	const validateContinue = () => {
		let validated = false;
		if (
			aircraftPrice != "" &&
			downPayment != "" &&
			interestRate != "" &&
			paymentTerm != "" &&
			marketValuePrice != "" &&
			aircraftInsurance != ""
		) {
			validated = true;
		} else {
			validated = false;
		}
		return validated;
	};

	return (
		<motion.div>
			<UtilityClass.CustomText
				textSize="title"
				fontWeight="semibold"
				color="black"
				content={"Finance Assumptions"}
			/>
			<UtilityClass.Separator />
			<div className="max-w-lg mx-auto lg:w-1/4 md:w-2/5 w-7/12">
				<UtilityClass.DefaultInput
					combinedDropdown={[true, marketList]}
					value={chooseAircraftPrice}
					leftSquareLabelBool={true}
					leftSquareLabelText={"USD"}
					placeholder={"Aircraft Acquisition Price"}
					rightSquareLabelBool={true}
					rightSquareLabelText={"million"}
					width="full"
					includeDefaults={defaultAircraft}
					defaultVals={aircraftData}
					defaultValue={aircraftData}
					regex={/^(0?|[1-9]\d{0,2})(\.\d{0,2})?$/}
				/>
				<UtilityClass.DefaultInput
					combinedDropdown={[true, downPaymentList]}
					value={chooseDownPayment}
					placeholder={"Down Payment/DP (% of Price)"}
					rightSquareLabelBool={true}
					rightSquareLabelText={"%"}
					width="full"
					includeDefaults={defaultDownPayment}
					defaultVals={downPaymentData}
					defaultValue={downPaymentData}
					regex={/^(0?|[1-8]?\d?|90)$/}
				/>
				<UtilityClass.DefaultInput
					combinedDropdown={[true, interestRateList]}
					value={chooseInterestRate}
					placeholder={"Interest Rate"}
					rightSquareLabelBool={true}
					rightSquareLabelText={"%"}
					width="full"
					includeDefaults={defaultInterest}
					defaultVals={interestData}
					defaultValue={interestData}
					regex={/^((0?|[1-2]?[0-9])(\.\d{0,2})?|30)$/}
				/>
				<UtilityClass.DefaultInput
					combinedDropdown={[true, paymentTermList]}
					value={choosePaymentTerm}
					placeholder={"Payment Term"}
					rightSquareLabelBool={true}
					rightSquareLabelText={"Years"}
					width="full"
					includeDefaults={defaultPayment}
					defaultVals={paymentData}
					defaultValue={paymentData}
					regex={/^(|[1-9]|1[0-5])$/}
				/>
				<br />
				<UtilityClass.DefaultInput
					combinedDropdown={[true, acquisitionData]}
					defaultValue={marketValuePrice}
					labelBool={true}
					label="Market Values"
					toolTip={true}
					toolTipDesc={`Market values used to calculate aircraft insurance are provided as a function of aircraft age.`}
					placeholder={
						aircraftOwnership === "Finance"
							? "Market Values"
							: "Aircraft Lease Rate"
					}
					leftSquareLabelBool
					leftSquareLabelText={
						aircraftOwnership === "Finance" ? "Million USD" : "Million USD"
					}
					value={setMarketValuePrice}
					width="full"
					includeDefaults={defaultMarketValue}
					regex={
						aircraftOwnership === "Finance"
							? /^(0?|[1-9]\d{0,2})(\.\d{0,2})?$/
							: /^[0-9]{0,6}$/
					}
					defaultVals={marketValue}
				/>
				<UtilityClass.DefaultInput
					defaultValue={aircraftInsuranceSelector}
					combinedDropdown={[true, insuranceData[0]]}
					labelBool={true}
					label="Aircraft Insurance"
					placeholder={"Aircraft Insurance Rate"}
					rightSquareLabelBool={true}
					rightSquareLabelText={"% Per Year"}
					toolTip={true}
					toolTipDesc="% of value"
					value={chooseAircraftInsurance}
					width="full"
					includeDefaults={defaultInsurance}
					regex={/^(0?(\.\d{0,1})?|[1-9]\d{0,1}(\.\d{0,1})?|30(\.0)?)?$/}
					//  defaultVals={insurance}
				/>
			</div>
			<UtilityClass.BackNextButtons
				nextButtonText="NEXT"
				backLink={"/forms/" + packageType + "/FixedOperatingCost"}
				nextLink={
					"/forms/" +
					packageType +
					`${
						packageLevel === "Basic" && numberAircraft < 2
							? "/CashOperatingCosts"
							: "/FixedOperatingCost2"
					}`
				}
				dispatchInputs={dispatchInputs}
				validation={validateContinue()}
			/>
		</motion.div>
	);
};

export default FinanceAssumptions;
