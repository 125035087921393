export const perfPaths = (packageLevel) => {
	return [
		"/forms/Performance/",
		"/forms/Performance/AircraftEngine",
		"/forms/Performance/CustomConfigWeights",
		packageLevel === "Premium" || packageLevel === "Advanced"
			? "/forms/Performance/OEM2"
			: undefined,
		packageLevel === "Premium" || packageLevel === "Advanced"
			? "/forms/Performance/AircraftEngine2"
			: undefined,
		packageLevel === "Premium" && "/forms/Performance/CustomConfigWeights2",
		(packageLevel === "Premium" || packageLevel === "Advanced") &&
			"/forms/Performance/SelectedAircraft",
		"/forms/Performance/RoutesAndDistances",
		// packageLevel === "Premium" && "/forms/Performance/RoutesAndDistances2",
		"/forms/Performance/EnvironmentalConditions",
		"/forms/Performance/AirportConditions",
		"/forms/Performance/CustomAirportConditions",
		// "/forms/Performance/CustomAirportConditions2",
		// packageLevel === "Premium" &&
		//     "/forms/Performance/CustomAirportConditions3",
		"/forms/Performance/InputReview",
		"/forms/Performance/LogoUpload",
		"/forms/Performance/Submission",
		"/forms/Performance/Thanks",
	];
};

export const econPaths = (packageLevel) => {
	return [
		"/forms/Economic/",
		"/forms/Economic/AircraftEngine",
		"/forms/Economic/CustomConfigWeights",
		packageLevel === "Premium" && "/forms/Economic/OEM2",
		packageLevel === "Premium" && "/forms/Economic/AircraftEngine2",
		packageLevel === "Premium" && "/forms/Economic/CustomConfigWeights2",
		(packageLevel === "Premium" || packageLevel === "Advanced") &&
			"/forms/Economic/SelectedAircraft",
		"/forms/Economic/RoutesAndDistances",
		// packageLevel === "Premium" && "/forms/Economic/RoutesAndDistances2",
		"/forms/Economic/EnvironmentalConditions",
		"/forms/Economic/AirportConditions",
		"/forms/Economic/CustomAirportConditions",
		// "/forms/Economic/CustomAirportConditions2",
		// packageLevel === "Premium" &&
		//     "/forms/Economic/CustomAirportConditions3",
		"/forms/Economic/CostAssumptions",
		"/forms/Economic/FixedOperatingCost",
		"/forms/Economic/FinanceAssumptions",
		"/forms/Economic/LeaseRate",
		(packageLevel === "Premium" || packageLevel === "Advanced") &&
			"/forms/Economic/FixedOperatingCost2",
		(packageLevel === "Premium" || packageLevel === "Advanced") &&
			"/forms/Economic/FixedOperatingCost3",
		(packageLevel === "Premium" || packageLevel === "Advanced") &&
			"/forms/Economic/LeaseRate2",
		(packageLevel === "Premium" || packageLevel === "Advanced") &&
			"/forms/Economic/FinanceAssumptions2",
		"/forms/Economic/CashOperatingCosts",
		"/forms/Economic/CrewLoading",
		(packageLevel === "Premium" || packageLevel === "Advanced") &&
			"/forms/Economic/CrewLoading2",
		"/forms/Economic/AirportAndNavigationCharges",
		"/forms/Economic/CustomAirportNavigationCharges",
		// packageLevel === "Premium" &&
		//     "/forms/Economic/CustomAirportNavigationCharges2",
		"/forms/Economic/InputReview",
		"/forms/Economic/InputReviewEcon",
		"/forms/Economic/LogoUpload",
		"/forms/Economic/Submission",
		"/forms/Economic/Thanks",
	];
};

export const demandPaths = (packageLevel) => {
	return [
		"/forms/Demand/",
		packageLevel === "Basic" && "/forms/Demand/Schedule",
		"/forms/Demand/InputReview",
		"/forms/Demand/LogoUpload",
		"/forms/Demand/Submission",
		"/forms/Demand/Thanks",
	];
};
