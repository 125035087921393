import React from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const SelectedAircraft = () => {
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);

	let econData = useSelector((state) => state.econData);
	let perfData = useSelector((state) => state.performanceData);
	let data;
	if (packageType === "Performance") {
		data = perfData;
	} else {
		data = econData;
	}

	let acType1 = "";
	let acType2 = "";
	let acType3 = "";
	if (packageType === "Performance") {
		if (perfData.acType.length > 0) {
			acType1 = data.acType;
		} else {
			acType1 = "Default";
		}
		if (perfData.acType2.length > 0) {
			acType2 = data.acType2;
		} else {
			acType2 = "Default";
		}
		if (perfData.acType3.length > 0) {
			acType3 = data.acType3;
		} else {
			acType3 = "Default";
		}
	} else {
		if (econData.acType.length > 0) {
			acType1 = data.acType;
		} else {
			acType1 = "Default";
		}
		if (econData.acType2.length > 0) {
			acType2 = data.acType2;
		} else {
			acType2 = "Default";
		}
		if (econData.acType3.length > 0) {
			acType3 = data.acType3;
		} else {
			acType3 = "Default";
		}
	}

	const acChosenString = () => {
		if (data.numberAircraft == 1) {
			return `Aircraft 1: ${acType1}\n`;
		} else if (
			data.numberAircraft > 1 &&
			data.numberAircraft < 3 &&
			(packageLevel == "Advanced" || packageLevel == "Premium")
		) {
			return `Aircraft 1: ${acType1}\n Aircraft 2: ${acType2}\n`;
		} else if (data.numberAircraft > 2 && packageLevel == "Premium") {
			return `Aircraft 1: ${acType1}\n Aircraft 2: ${acType2}\n Aircraft 3: ${acType3}`;
		}
	};

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.1 }}
		>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="bold"
				color="black"
				content={`Selected Aircraft Type`}
			/>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="semibold"
				color="black"
				content={acChosenString()}
			/>
			<br />
			<br />
			<br />
			<br />
			<UtilityClass.CustomText
				textSize="title"
				fontWeight="semibold"
				color="red"
				content={`The following routes and Operating assumptions will be applied to all aircraft types`}
			/>
			<br />
			<UtilityClass.BackNextButtons
				nextButtonText="NEXT"
				backLink={"/forms/" + packageType + "/AircraftEngine"}
				nextLink={"/forms/" + packageType + "/RoutesAndDistances"}
				validation={true}
			/>
		</motion.div>
	);
};

export default SelectedAircraft;
