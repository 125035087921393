import {
	selectFaPerAircraftData,
	selectEconOrderNumber,
	selectEconNumberAircraft,
	selectPilotsPerAircraftData,
	selectCabinCrewData,
	selectPilotCrewData,
	selectRegion,
	selectRegionData,
	selectMarketData,
	selectDownPaymentData,
	selectInterestRateData,
	selectPaymentTermData,
	selectFuelPriceData,
	selectEconFetched,
	selectEconOEMList,
	selectLocation,
	selectEconOEM,
	selectEconOEM2,
	selectEconOEM3,
	selectEconACType,
	selectEconACType2,
	selectEconACType3,
	selectEconACList,
	selectEconACDefault,
	selectEconACDefault2,
	selectEconACDefault3,
	selectEconRnD,
	selectEconRnD2,
	selectEconConditions,
	selectEconConditionsTwo,
	selectEconConditionsThree,
	selectEconConfigRowsTwo,
	selectEconConfigRowsThree,
	selectEconConfigRows,
	chooseEconConfig,
	chooseFirstEconWeight,
	chooseSecondEconWeight,
	chooseThirdEconWeight,
	selectFixedOperatingCostChoice,
	selectEconAircraftInsurance,
	selectAircraftOwnershipChoice,
	selectEconLeaseRate,
	selectLeaseRateData,
	selectFinanceAssumptions,
	selectCashOperatingCosts,
	selectCustomEconAirportConditions,
	selectCustomEconAirportConditions2,
	selectCustomEconAirportConditions3,
	selectAirportNavigationChoice,
	selectAirportNavigationCharges,
	selectAirportNavigationChargesTwo,
	selectCrewLoading1,
	selectCrewLoading2,
	selectCrewLoading3,
	selectEconAirportConditions,
	resetEcon,
	selectEconLogoUpload,
	selectEconSubmission,
} from "./econDataSlice";
import {
	selectOrderNumber,
	selectNumberAircraft,
	selectAirports,
	selectFetched,
	selectOEMList,
	selectOEM,
	selectOEM2,
	selectOEM3,
	selectACType,
	selectACType2,
	selectACType3,
	selectACList,
	selectACDefault,
	selectACDefault2,
	selectACDefault3,
	selectRnD,
	selectRnD2,
	selectConditions,
	selectConditionsTwo,
	selectConditionsThree,
	chooseFirstPerfWeight,
	chooseSecondPerfWeight,
	chooseThirdPerfWeight,
	selectConfigRows,
	selectConfigRowsTwo,
	selectConfigRowsThree,
	selectCustomAirportConditions,
	selectCustomAirportConditions2,
	selectCustomAirportConditions3,
	selectAirportConditions,
	selectSubmission,
	selectLogoUpload,
} from "./performanceDataSlice";
import {
	setAirline,
	selectDemandOrderNumber,
	setScheduledStartDate,
	setMarkets,
	setSchedule,
	setDemandLogoUpload,
	setDemandSubmission,
	setCustomScheduleChoice,
} from "./demandDataSlice";

const EconDispatchers = {
	OEMChoice: selectEconOEM,
	OEMChoice2: selectEconOEM2,
	OEMChoice3: selectEconOEM3,
	OEMList: selectEconOEMList,
	numberAircraft: selectEconNumberAircraft,
	RnD: selectEconRnD,
	RnD2: selectEconRnD2,
	acDefault: selectEconACDefault,
	acDefault2: selectEconACDefault2,
	acDefault3: selectEconACDefault3,
	acList: selectEconACList,
	acType: selectEconACType,
	acType2: selectEconACType2,
	acType3: selectEconACType3,
	aircraftInsurance1: selectEconAircraftInsurance,
	aircraftInsurance2: selectEconAircraftInsurance,
	aircraftOwnership1: selectAircraftOwnershipChoice,
	aircraftOwnership2: selectAircraftOwnershipChoice,
	airportConditions: selectEconAirportConditions,
	airportNavigationCharges: selectAirportNavigationCharges,
	airportNavigationChargesTwo: selectAirportNavigationChargesTwo,
	airportNavigationChoice: selectAirportNavigationChoice,
	cabinCrewData: selectCabinCrewData,
	cashOperatingCosts: selectCashOperatingCosts,
	crewLoading1: selectCrewLoading1,
	crewLoading2: selectCrewLoading2,
	crewLoading3: selectCrewLoading3,
	conditions: selectEconConditions,
	conditionsTwo: selectEconConditionsTwo,
	conditionsThree: selectEconConditionsThree,
	config: chooseFirstEconWeight,
	configRows: selectEconConfigRows,
	configRowsTwo: selectEconConfigRowsTwo,
	configRowsThree: selectEconConfigRowsThree,
	configTwo: chooseSecondEconWeight,
	configThree: chooseThirdEconWeight,
	customAirportConditions: selectCustomEconAirportConditions,
	customAirportConditions2: selectCustomEconAirportConditions2,
	customAirportConditions3: selectCustomEconAirportConditions3,
	downPaymentData: selectDownPaymentData,
	faPerAircraftData: selectFaPerAircraftData,
	fetched: selectEconFetched,
	financeAssumptions1: selectFinanceAssumptions,
	financeAssumptions2: selectFinanceAssumptions,
	fixedOperatingCostChoice: selectFixedOperatingCostChoice,
	fuelPriceData: selectFuelPriceData,
	id: undefined,
	interestRateData: selectInterestRateData,
	leaseRate1: selectEconLeaseRate,
	leaseRate2: selectEconLeaseRate,
	leaseRateData: selectLeaseRateData,
	location: selectLocation,
	marketData: selectMarketData,
	paymentTermData: selectPaymentTermData,
	pilotCrewData: selectPilotCrewData,
	pilotsPerAircraftData: selectPilotsPerAircraftData,
	region: selectRegion,
	regionData: selectRegionData,
	submission: selectEconSubmission,
	logoUpload: selectEconLogoUpload,
};

const PerfDispatchers = {
	airports: selectAirports,
	fetched: selectFetched,
	OEMList: selectOEMList,
	numberAircraft: selectNumberAircraft,
	OEMChoice: selectOEM,
	OEMChoice2: selectOEM2,
	OEMChoice3: selectOEM3,
	acType: selectACType,
	acType2: selectACType2,
	acType3: selectACType3,
	acList: selectACList,
	acDefault: selectACDefault,
	acDefault2: selectACDefault2,
	acDefault3: selectACDefault3,
	RnD: selectRnD,
	RnD2: selectRnD2,
	conditions: selectConditions,
	conditionsTwo: selectConditionsTwo,
	conditionsThree: selectConditionsThree,
	config: chooseFirstPerfWeight,
	configTwo: chooseSecondPerfWeight,
	configThree: chooseThirdPerfWeight,
	configRows: selectConfigRows,
	configRowsTwo: selectConfigRowsTwo,
	configRowsThree: selectConfigRowsThree,
	customAirportConditions: selectCustomAirportConditions,
	customAirportConditions2: selectCustomAirportConditions2,
	customAirportConditions3: selectCustomAirportConditions3,
	airportConditions: selectAirportConditions,
	submission: selectSubmission,
	logoUpload: selectLogoUpload,
};

const DemandDispatchers = {
	airline: setAirline,
	orderNumber: selectDemandOrderNumber,
	scheduledStartDate: setScheduledStartDate,
	markets: setMarkets,
	schedule: setSchedule,
	submission: setDemandSubmission,
	logoUpload: setDemandLogoUpload,
	customScheduleChoice: setCustomScheduleChoice,
};

export default { EconDispatchers, PerfDispatchers, DemandDispatchers };
