import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	orderNumber: 0,
	orderID: 0,
	airports: {},
	fetched: false,
	OEMList: [],
	OEMChoice: "Default",
	acType: [],
	acList: [],
	acDefault: "No, use default values.",
	OEMChoice2: "Default",
	OEMChoice3: "Default",
	numberAircraft: 1,
	acType2: [],
	acType3: [],
	normalSpeedMachData: [],
	logoUpload: {
		logoChoice: "No",
		logoImage: undefined,
		resultChoice: "No",
	},
	submission: {
		firstName: "",
		lastName: "",
		company: "",
		phone: "",
		email: "",
	},
	acDefault2: "No, use default values.",
	acDefault3: "No, use default values.",
	RnD: {
		iataAirportCode: undefined,
		gcd: undefined,
		customDist: undefined,
		distanceUnit: "Nautical Miles",
	},
	RnD2: {
		iataAirportCode: undefined,
		gcd: undefined,
		customDist: undefined,
		distanceUnit: "Nautical Miles",
	},
	RnD2: {
		iataAirportCode: undefined,
		gcd: undefined,
		customDist: undefined,
	},
	conditions: {
		temp: "ISA",
		wind: "Annual",
		windProb: undefined,
		fuelReserve: "FAR International",
		payloadCrit: "Passenger Load",
		payloadFactor: undefined,
		pbAllowance: undefined,
		aircraftUtil: undefined,
		acType: undefined,
		climbSpeed: "Default",
		cruiseSpeed: "Long Range Cruise",
		descentSpeed: "Default",
		fixedMach: undefined,
		divClimbSpeed: "Default",
		divCruiseSpeed: "Long Range Cruise",
		divDescSpeed: "Default",
		divFixedMach: undefined,
	},
	conditionsTwo: {
		climbSpeed: "Default",
		cruiseSpeed: "Long Range Cruise",
		descentSpeed: "Default",
		fixedMach: undefined,
		divClimbSpeed: "Default",
		divCruiseSpeed: "Long Range Cruise",
		divDescSpeed: "Default",
		divFixedMach: undefined,
	},
	conditionsThree: {
		climbSpeed: "Default",
		cruiseSpeed: "Long Range Cruise",
		descentSpeed: "Default",
		fixedMach: undefined,
		divClimbSpeed: "Default",
		divCruiseSpeed: "Long Range Cruise",
		divDescSpeed: "Default",
		divFixedMach: undefined,
	},
	config: {
		seats: undefined,
		mtow: undefined,
		mlw: undefined,
		owe: undefined,
		payload: undefined,
		unit: "Kg",
	},
	configRows: undefined,
	configTwo: {
		seats: undefined,
		mtow: undefined,
		mlw: undefined,
		owe: undefined,
		payload: undefined,
		unit: "Kg",
	},
	configRowsTwo: undefined,
	configThree: {
		seats: undefined,
		mtow: undefined,
		mlw: undefined,
		owe: undefined,
		payload: undefined,
		unit: "Kg",
	},
	configRowsThree: undefined,
	customAirportConditions: {
		override: Array(20).fill([false]),
		airports: undefined,
		ISA: undefined,
		OAT: undefined,
		rwyWind: undefined,
		taxiOut: undefined,
		taxiIn: undefined,
	},
	customAirportConditions2: {
		override: Array(20).fill([false]),
		airports: undefined,
		ISA: undefined,
		OAT: undefined,
		rwyWind: undefined,
		taxiOut: undefined,
		taxiIn: undefined,
	},
	customAirportConditions3: {
		override: Array(20).fill([false]),
		airports: undefined,
		ISA: undefined,
		OAT: undefined,
		rwyWind: undefined,
		taxiOut: undefined,
		taxiIn: undefined,
	},
	airportConditions: {
		ISA: "ISA",
		rwyWind: "0",
		taxiOut: "10",
		taxiIn: "10",
		input: "No, use global assumptions.",
	},
};

export const performanceDataSlice = createSlice({
	name: "performanceData",
	initialState,
	reducers: {
		selectOrderNumber: (state, arrayData) => {
			state.orderNumber = arrayData.payload;
			// console.log(state.fetched);
		},
		selectNormalSpeedMachData: (state, arrayData) => {
			state.normalSpeedMachData = arrayData.payload;
		},
		selectOrderID: (state, arrayData) => {
			state.orderID = arrayData.payload;
			// console.log(state.fetched);
		},
		selectSubmission: (state, arrayData) => {
			state.submission = arrayData.payload;
			// console.log(state.fetched);
		},
		selectAirports: (state, arrayData) => {
			state.airports = arrayData.payload;
			// console.log(state.fetched);
		},
		selectFetched: (state, arrayData) => {
			state.fetched = arrayData.payload;
			// console.log(state.fetched);
		},
		selectOEMList: (state, arrayData) => {
			state.OEMList = arrayData.payload;
			// console.log(state.OEMList);
		},
		selectOEM: (state, arrayData) => {
			state.OEMChoice = arrayData.payload;
			// console.log(state.OEMChoice);
		},
		selectOEM2: (state, arrayData) => {
			state.OEMChoice2 = arrayData.payload;
			// console.log(state.OEMChoice2);
		},
		selectOEM3: (state, arrayData) => {
			state.OEMChoice3 = arrayData.payload;
			// console.log(state.OEMChoice3);
		},
		selectNumberAircraft: (state, arrayData) => {
			state.numberAircraft = arrayData.payload;
		},
		selectACType: (state, arrayData) => {
			state.acType = arrayData.payload;
			console.log(state.acType);
		},
		selectACList: (state, arrayData) => {
			state.acList = arrayData.payload;
			//console.log(state.acList);
		},
		selectACType2: (state, arrayData) => {
			state.acType2 = arrayData.payload;
			//console.log(state.acType2);
		},
		selectACType3: (state, arrayData) => {
			state.acType3 = arrayData.payload;
			//console.log(state.acType3);
		},
		selectACDefault: (state, arrayData) => {
			state.acDefault = arrayData.payload;
			// console.log(state.acDefault);
		},
		selectACDefault2: (state, arrayData) => {
			state.acDefault2 = arrayData.payload;
			//  console.log(state.acDefault2);
		},
		selectACDefault3: (state, arrayData) => {
			state.acDefault3 = arrayData.payload;
			//  console.log(state.acDefault3);
		},
		selectRnD: (state, arrayData) => {
			state.RnD = arrayData.payload;
			// console.log(state.RnD);
		},
		selectLogoUpload: (state, arrayData) => {
			state.logoUpload = arrayData.payload;
			// console.log(state.RnD);
		},
		selectRnD2: (state, arrayData) => {
			state.RnD2 = arrayData.payload;
			//   console.log(state.RnD2);
		},
		selectConditions: (state, arrayData) => {
			state.conditions = arrayData.payload;
			console.log(state.conditions);
		},
		selectConditionsTwo: (state, arrayData) => {
			state.conditionsTwo = arrayData.payload;
			// console.log(state.conditionsTwo, 'cond two');
		},
		selectConditionsThree: (state, arrayData) => {
			state.conditionsThree = arrayData.payload;
			// console.log(state.conditionsThree, 'cond three');
		},
		chooseFirstPerfWeight: (state, arrayData) => {
			state.config = arrayData.payload;
			// console.log(state.config);
		},
		selectConfigRows: (state, arrayData) => {
			state.configRows = arrayData.payload;
			// console.log(state.config);
		},
		selectConfigRowsTwo: (state, arrayData) => {
			state.configRowsTwo = arrayData.payload;
			// console.log(state.config);
		},
		selectConfigRowsThree: (state, arrayData) => {
			state.configRowsThree = arrayData.payload;
			// console.log(state.config);
		},
		chooseSecondPerfWeight: (state, arrayData) => {
			state.configTwo = arrayData.payload;
			// console.log(state.configTwo);
		},
		chooseThirdPerfWeight: (state, arrayData) => {
			state.configThree = arrayData.payload;
			// console.log(state.configThree);
		},
		selectCustomAirportConditions: (state, arrayData) => {
			state.customAirportConditions = arrayData.payload;
			console.log(state.customAirportConditions);
		},
		selectCustomAirportConditions2: (state, arrayData) => {
			state.customAirportConditions2 = arrayData.payload;
			console.log(state.customAirportConditions2);
		},
		selectCustomAirportConditions3: (state, arrayData) => {
			state.customAirportConditions3 = arrayData.payload;
			console.log(state.customAirportConditions3);
		},
		selectAirportConditions: (state, arrayData) => {
			state.airportConditions = arrayData.payload;
			console.log(state.airportConditions);
		},
		resetPerf: (state) => {
			state.numberAircraft = 1;
			state.orderID = 0;
			state.orderNumber = 0;
			state.airports = {};
			state.fetched = false;
			state.OEMList = [];
			state.OEMChoice = "Default";
			state.acType = [];
			state.acList = [];
			state.normalSpeedMachData = [];
			state.OEMChoice2 = "Default";
			state.OEMChoice3 = "Default";
			state.acType2 = [];
			state.acType3 = [];
			state.acDefault = "No, use default values.";
			state.acDefault2 = "No, use default values.";
			state.acDefault3 = "No, use default values.";
			state.RnD = {
				iataAirportCode: undefined,
				gcd: undefined,
				customDist: undefined,
				distanceUnit: "Nautical Miles",
			};
			state.submission = {
				firstName: "",
				lastName: "",
				company: "",
				phone: "",
				email: "",
			};
			state.RnD2 = {
				iataAirportCode: undefined,
				gcd: undefined,
				customDist: undefined,
				distanceUnit: "Nautical Miles",
			};
			state.RnD2 = {
				iataAirportCode: undefined,
				gcd: undefined,
				customDist: undefined,
			};
			state.logoUpload = {
				logoChoice: "No",
				logoImage: undefined,
				resultChoice: "No",
			};
			state.conditions = {
				temp: "ISA",
				wind: "Annual",
				windProb: undefined,
				fuelReserve: "FAR International",
				payloadCrit: "Passenger Load",
				payloadFactor: undefined,
				pbAllowance: undefined,
				aircraftUtil: undefined,
				acType: undefined,
				climbSpeed: "Default",
				cruiseSpeed: "Long Range Cruise",
				descentSpeed: "Default",
				fixedMach: undefined,
				divClimbSpeed: "Default",
				divCruiseSpeed: "Long Range Cruise",
				divDescSpeed: "Default",
				divFixedMach: undefined,
			};
			state.conditionsTwo = {
				climbSpeed: "Default",
				cruiseSpeed: "Long Range Cruise",
				descentSpeed: "Default",
				fixedMach: undefined,
				divClimbSpeed: "Default",
				divCruiseSpeed: "Long Range Cruise",
				divDescSpeed: "Default",
				divFixedMach: undefined,
			};
			state.conditionsThree = {
				climbSpeed: "Default",
				cruiseSpeed: "Long Range Cruise",
				descentSpeed: "Default",
				fixedMach: undefined,
				divClimbSpeed: "Default",
				divCruiseSpeed: "Long Range Cruise",
				divDescSpeed: "Default",
				divFixedMach: undefined,
			};
			state.config = {
				seats: undefined,
				mtow: undefined,
				mlw: undefined,
				owe: undefined,
				payload: undefined,
				unit: "Kg",
			};
			state.configRows = undefined;
			state.configTwo = {
				seats: undefined,
				mtow: undefined,
				mlw: undefined,
				owe: undefined,
				payload: undefined,
				unit: "Kg",
			};
			state.configRowsTwo = undefined;
			state.configThree = {
				seats: undefined,
				mtow: undefined,
				mlw: undefined,
				owe: undefined,
				payload: undefined,
				unit: "Kg",
			};
			state.configRowsThree = undefined;
			state.airportConditions = {
				ISA: "ISA",
				rwyWind: "0",
				taxiOut: "10",
				taxiIn: "10",
				input: "No, use global assumptions.",
			};
			state.customAirportConditions = {
				override: Array(20).fill([false]),
				airports: undefined,
				ISA: undefined,
				OAT: undefined,
				rwyWind: undefined,
				taxiOut: undefined,
				taxiIn: undefined,
			};
			state.customAirportConditions2 = {
				override: Array(20).fill([false]),
				airports: undefined,
				ISA: undefined,
				OAT: undefined,
				rwyWind: undefined,
				taxiOut: undefined,
				taxiIn: undefined,
			};
			state.customAirportConditions3 = {
				override: Array(20).fill([false]),
				airports: undefined,
				ISA: undefined,
				OAT: undefined,
				rwyWind: undefined,
				taxiOut: undefined,
				taxiIn: undefined,
			};
		},
	},
});

export const {
	selectLogoUpload,
	selectNormalSpeedMachData,
	selectOrderID,
	selectOrderNumber,
	selectAirports,
	selectFetched,
	selectOEMList,
	selectOEM,
	selectOEM2,
	selectOEM3,
	selectNumberAircraft,
	selectACType,
	selectACType2,
	selectACType3,
	selectSubmission,
	selectACList,
	selectACDefault,
	selectACDefault2,
	selectACDefault3,
	selectRnD,
	selectRnD2,
	selectConditions,
	selectConditionsTwo,
	selectConditionsThree,
	chooseFirstPerfWeight,
	chooseSecondPerfWeight,
	chooseThirdPerfWeight,
	selectConfigRows,
	selectConfigRowsTwo,
	selectConfigRowsThree,
	selectCustomAirportConditions,
	selectCustomAirportConditions2,
	selectCustomAirportConditions3,
	selectAirportConditions,
	resetPerf,
} = performanceDataSlice.actions;

export default performanceDataSlice.reducer;
