import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { selectNumberAircraft } from "../../reducers/performanceDataSlice";
import { selectEconNumberAircraft } from "../../reducers/econDataSlice";
import AircraftEngine from "./AircraftEngine";
import AircraftEngine2 from "./AircraftEngine2";
import AircraftEngine3 from "./AircraftEngine3";

const OEMSelection = () => {
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let packageType = useSelector((state) => state.packageType.value);

	let econData = useSelector((state) => state.econData);
	let perfData = useSelector((state) => state.performanceData);

	let data;
	if (packageType === "Performance") {
		data = perfData;
	} else {
		data = econData;
	}

	const dispatch = useDispatch();
	const [aircraftCounter, setAircraftCounter] = useState(data.numberAircraft);
	// const [aircraftCounter, setAircraftCounter] = useState(1);

	const handleAddAircraft = () => {
		setAircraftCounter((prevCount) => prevCount + 1);
	};

	useEffect(() => {
		// console.log(aircraftCounter);
	}, [aircraftCounter]);

	const dispatchInputs = () => {
		if (packageType === "Performance") {
			dispatch(selectNumberAircraft(aircraftCounter));
		} else if (packageType === "Economic") {
			dispatch(selectEconNumberAircraft(aircraftCounter));
		}
	};

	const [validAC1, setValidAC1] = useState(false);
	const [validAC2, setValidAC2] = useState();
	const [validAC3, setValidAC3] = useState();

	//validation based on inputs
	const validateContinue = () => {
		let validated = false;

		// Validate Engine 1
		const validateACEng1 = validAC1;

		// Validate Engine 2
		// const validateACEng2 = aircraftEngine2Ref.current?.validateContinue?.();
		const validateACEng2 = validAC2;

		const validateACEng3 = validAC3;

		console.log("validateACEng1: ", validateACEng1);
		console.log("validateACEng2: ", validateACEng2);
		console.log("validateACEng3: ", validateACEng3);

		// Combine validations
		if (validateACEng2 !== undefined && validateACEng3 !== undefined) {
			validated = validateACEng1 && validateACEng2 && validateACEng3;
		} else if (validateACEng2 !== undefined) {
			validated = validateACEng1 && validateACEng2;
		} else {
			validated = validateACEng1;
		}

		return validated;
	};

	const additionButton = () => {
		if (packageLevel === "Advanced" && aircraftCounter < 2) {
			return (
				<div
					className="flex flex-col items-center mx-0.5 my-2"
					style={{ minWidth: "120px" }}
				>
					<UtilityClass.Button
						className="sticky"
						text={""}
						icon={"add"}
						width={""}
						onClickCommand={() => {
							handleAddAircraft();
						}}
					/>
				</div>
			);
		} else if (packageLevel === "Premium" && aircraftCounter < 3) {
			return (
				<div
					className="flex flex-col items-center mx-0.5 my-2"
					style={{ minWidth: "120px" }}
				>
					<UtilityClass.Button
						className="sticky"
						text={""}
						icon={"add"}
						width={""}
						onClickCommand={() => {
							handleAddAircraft();
						}}
					/>
				</div>
			);
		}
	};

	return (
		<motion.div>
			<div
				className={`${
					packageLevel !== "Basic"
						? "flex flex-wrap flex-row items-start select-none mx-0.5 "
						: ""
				}`}
				key={`engine-${aircraftCounter}`}
			>
				<div className="flex-1" key={`engine-1-${aircraftCounter}`}>
					<AircraftEngine
						key={`engine-1-inst-${aircraftCounter}`}
						onValidateChange={setValidAC1}
					/>
				</div>

				{packageLevel !== "Basic" && aircraftCounter >= 2 && (
					<div className="flex-1" key={`engine-2-${aircraftCounter}`}>
						<AircraftEngine2
							key={`engine-2-inst-${aircraftCounter}`}
							onValidateChange={setValidAC2}
						/>
					</div>
				)}
				{packageLevel !== "Basic" &&
					packageLevel !== "Advanced" &&
					aircraftCounter >= 3 && (
						<div className="flex-1" key={`engine-3-${aircraftCounter}`}>
							<AircraftEngine3
								key={`engine-3-inst-${aircraftCounter}`}
								onValidateChange={setValidAC3}
							/>
						</div>
					)}
				{additionButton()}
				{/* {packageLevel !== "Basic" && aircraftCounter < 3 && (
					<div
						className="flex flex-col items-center mx-0.5 my-2"
						style={{ minWidth: "120px" }}
					>
						<UtilityClass.Button
							className="sticky"
							text={""}
							icon={"add"}
							width={""}
							onClickCommand={() => {
								handleAddAircraft();
							}}
						/>
					</div>
				)} */}
			</div>
			<UtilityClass.BackNextButtons
				nextButtonText="NEXT"
				backButtonBool={false}
				// backLink={"/forms/" + packageType}
				nextLink={
					packageLevel == "Basic" && aircraftCounter < 2
						? "/forms/" + packageType + "/RoutesAndDistances"
						: "/forms/" + packageType + "/SelectedAircraft"
				}
				validation={validateContinue()}
				// validation={true}
				dispatchInputs={dispatchInputs}
			/>
		</motion.div>
	);
};

export default OEMSelection;
