import React from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAirportNavigationCharges } from "../../reducers/econDataSlice";
import { motion } from "framer-motion";

const CustomAirportNavigationCharges = () => {
	// This component will be a child component that will be called/rendered from AirportAndNavigationCharges based on the user selection
	// if they want to input custom overrides (2nd selection)

	const [first, setFirst] = useState();
	const [second, setSecond] = useState();
	const [third, setThird] = useState();
	const [fourth, setFourth] = useState();
	const [fifth, setFifth] = useState();
	const [sixth, setSixth] = useState();
	const [seventh, setSeventh] = useState();
	const [eight, setEigth] = useState();
	const [ninth, setNinth] = useState();

	const [firstArr, setFirstArr] = useState([]);
	const firstTable = (type, index) => {
		firstArr.push([type, index]);
		setFirst(type);
	};

	let secondArr = [];
	const secondTable = (type) => {
		secondArr.push(type);
		setSecond(type);
	};

	const [thirdArr, setThirdArr] = useState([]);
	const thirdTable = (type, index) => {
		thirdArr.push([type, index]);
		setFirst(type);
	};

	let fourthArr = [];
	const fourthTable = (type) => {
		fourthArr.push(type);
		setFourth(type);
	};

	const [fifthArr, setFifthArr] = useState([]);
	const fifthTable = (type, index) => {
		fifthArr.push([type, index]);
		setFifth(type);
	};

	let sixthArr = [];
	const sixthTable = (type) => {
		sixthArr.push(type);
		setSixth(type);
	};

	let seventhArr = [];
	const seventhTable = (type) => {
		seventhArr.push(type);
		setSeventh(type);
	};

	let eigthArr = [];
	const eigthTable = (type) => {
		eigthArr.push(type);
		setEigth(type);
	};

	const [ninthArr, setNinthArr] = useState([]);
	const ninthTable = (type, index) => {
		ninthArr.push([type, index]);
		setNinth(type);
	};

	let data = useSelector((state) => {
		return state.econData;
	});
	let landingUnit = data.airportNavigationCharges.landingUnit;
	let landingRate = data.airportNavigationCharges.landingRate;
	let termNavigationUnit = data.airportNavigationCharges.termNavigationUnit;
	let termNavigationRate = data.airportNavigationCharges.termNavigationRate;
	let routeNavigationRate = data.airportNavigationCharges.routeNavigationRate;
	let routeNavigationUnit = data.airportNavigationCharges.routeNavigationUnit;
	let passHandling = data.airportNavigationCharges.passengerHandling;
	let airHandlingUnit = data.airportNavigationCharges.aircraftHandlingUnit;
	let airHandlingRate = data.airportNavigationCharges.aircraftHandlingRate;

	const [defaultLandingUnit, setdefaultLandingUnit] = useState(
		landingUnit === undefined ? false : true
	);
	const [defaultLandingRate, setdefaultLandingRate] = useState(
		landingRate === undefined ? false : true
	);
	const [defaultTermNavUnit, setDefaultTermNavUnit] = useState(
		termNavigationUnit === undefined ? false : true
	);
	const [defaultTermNavRate, setDefaultTermNavRate] = useState(
		termNavigationRate === undefined ? false : true
	);
	const [defaultRouteNavUnit, setDefaultRouteNavUnit] = useState(
		routeNavigationUnit === undefined ? false : true
	);
	const [defaultRouteNavRate, setDefaultRouteNavRate] = useState(
		routeNavigationRate === undefined ? false : true
	);
	const [defaultPassHandling, setdefaultPassHandling] = useState(
		passHandling === undefined ? false : true
	);
	const [defaultAirHandlingUnit, setDefaultAirHandlingUnit] = useState(
		airHandlingUnit === undefined ? false : true
	);
	const [defaultAirHandlingRate, setDefaultAirHandlingRate] = useState(
		airHandlingRate === undefined ? false : true
	);

	// redux calls
	let packageType = useSelector((state) => state.packageType.value);
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let routeData = useSelector((state) => state.econData.RnD.iataAirportCode);

	const getRoutes = () => {
		let out = [];
		if (routeData) {
			routeData.forEach((value) => {
				if (value[0] !== "" || value[2] !== "") {
					out.push((value[0] + " - " + value[2]).toUpperCase());
				} else {
					out.push("N/A - N/A");
				}
			});
			return out;
		}
	};

	useEffect(() => {
		if (defaultLandingUnit) {
			landingUnit.forEach((value) => {
				firstArr.push(value);
			});
		}
		if (defaultTermNavUnit) {
			termNavigationUnit.forEach((value) => {
				thirdArr.push(value);
			});
		}
		if (defaultRouteNavUnit) {
			routeNavigationUnit.forEach((value) => {
				fifthArr.push(value);
			});
		}
		if (defaultAirHandlingUnit) {
			airHandlingUnit.forEach((value) => {
				ninthArr.push(value);
			});
		}
	}, []);

	let navInputs = {
		landingUnit: undefined,
		landingRate: undefined,
		termNavigationRate: undefined,
		termNavigationUnit: undefined,
		routeNavigationRate: undefined,
		routeNavigationUnit: undefined,
		passengerHandling: undefined,
		aircraftHandlingRate: undefined,
		aircraftHandlingUnit: undefined,
	};

	const dispatch = useDispatch();
	const dispatchInputs = () => {
		navInputs.landingUnit = firstArr;
		navInputs.landingRate = secondArr;
		navInputs.termNavigationUnit = thirdArr;
		navInputs.termNavigationRate = fourthArr;
		navInputs.routeNavigationUnit = fifthArr;
		navInputs.routeNavigationRate = sixthArr;
		navInputs.passengerHandling = seventhArr;
		navInputs.aircraftHandlingRate = eigthArr;
		navInputs.aircraftHandlingUnit = ninthArr;
		dispatch(selectAirportNavigationCharges(navInputs));
	};

	// check screen width
	var windowWidth = window.innerWidth;
	const [windowLarge, setWindowLarge] = useState(windowWidth >= 1024);
	window.onresize = handleResize;
	function handleResize() {
		windowWidth = window.innerWidth;
		if (windowWidth < 1024) {
			setWindowLarge(false);
		} else if (windowWidth <= 1441) {
			setWindowLarge(true);
		} else {
			setWindowLarge(true);
		}
	}

	return (
		<motion.div>
			<UtilityClass.CustomText
				textSize="title"
				fontWeight="semibold"
				color="black"
				content={"Custom Airport and Navigation Charges"}
			/>
			<UtilityClass.CustomText
				textSize="subtitle"
				fontWeight="semibold"
				color="grey"
				content={"Customize your assumptions with any combination of inputs"}
			/>
			<UtilityClass.Separator />
			<UtilityClass.CustomText
				textSize="subtitle"
				fontWeight="semibold"
				color="black"
				content={"Formulas"}
			/>
			<div className="flex justify-center">
				<div className="lg:grid lg:w-8/12 md:w-10/12 sm:w-11/12 lg:grid-cols-3 lg:gap-2 md:grid md:grid-cols-3 md:gap-2">
					<div className="flex flex-col justify-center -mt-6 px-4">
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="bold"
							color="grey"
							alignment="left"
							content={"Aiport Landing"}
						/>
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="thin"
							color="grey"
							alignment="left"
							content={
								"1 - MTOW (per tonne)\nLanding Charge = rate x MTOW (tonne)"
							}
						/>
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="thin"
							color="grey"
							alignment="left"
							content={
								"2 - MLW (per 1000 lbs)\nLanding Charge = rate x (MLW / 1000)"
							}
						/>
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="thin"
							color="grey"
							alignment="left"
							content={"3 - Amount (per landing)\nLanding Charge = rate"}
						/>
					</div>
					<div className="flex flex-col justify-center px-4">
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="bold"
							color="grey"
							alignment="left"
							content={"Terminal Navigation"}
						/>
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="thin"
							color="grey"
							alignment="left"
							content={
								"1 - MTOW (per tonne)\nTerminal Nav Charge = rate x sq. rt.(MTOW (tonne) / 50)"
							}
						/>
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="thin"
							color="grey"
							alignment="left"
							content={
								"2 - Amount (per landing and takeoff)\nTerminal Nav Charge = rate"
							}
						/>
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="semibold"
							color="grey"
							alignment="left"
							content={
								"*cost will be applied to origin and destination airport"
							}
						/>
					</div>
					<div className="flex flex-col justify-center px-4">
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="bold"
							color="grey"
							alignment="left"
							content={"En route Navigation"}
						/>
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="thin"
							color="grey"
							alignment="left"
							content={
								"1 - MTOW (per tonne)\nEn Route Charge = rate x (GCD (km) / 100) x sq. rt. (MTOW (tonne) / 50)"
							}
						/>
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="thin"
							color="grey"
							alignment="left"
							content={
								"2 - Amount (per 100 km)\nEn Route Charge = rate x (GCD (km) / 100)"
							}
						/>
						<UtilityClass.CustomText
							textSize="medium"
							fontWeight="thin"
							color="grey"
							alignment="left"
							content={"3 - Amount (per flight)\nEn Route Charge = rate"}
						/>
					</div>
				</div>
			</div>
			<UtilityClass.Separator />
			<UtilityClass.CustomText
				textSize="subtitle"
				fontWeight="semibold"
				color="black"
				content={`Routes 1 to ${
					packageLevel === "Basic"
						? "10"
						: packageLevel === "Advanced"
						? "15"
						: "20"
				}`}
			/>

			<div className="flex justify-center">
				<div className="grid w-9/12 lg:grid-cols-10 sm:grid-cols-1 gap-5 justify-center">
					{windowLarge && (
						<div className="flex col-span-1 justify-center">
							<div className="flex flex-col justify-center">
								<div className="flex flex-row justify-center">
									<div className="flex">
										<UtilityClass.CustomText
											textSize="large"
											fontWeight="semibold"
											color="black"
											content={"Routes"}
										/>
									</div>
								</div>
								<div className="flex flex-row justify-center">
									<div className="w-full -mt-2 flex">
										<UtilityClass.InputTable
											indexBool={true}
											routesBool
											indexBlack={true}
											rowNum={
												packageLevel === "Basic"
													? 10
													: packageLevel === "Advanced"
													? 15
													: 20
											}
											colNum={0}
											addRoutes={true}
											routes={getRoutes()}
											checkboxSmallerBottomMarginBool={true}
											checkboxLabelBottomMarginBool={false}
											captionBool={true}
											showIndex={Array(
												packageLevel === "Basic"
													? 10
													: packageLevel === "Advanced"
													? 15
													: 20
											).fill(true)}
											capitalize={true}
											width="full"
										/>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="flex col-span-3 justify-center">
						<div className="flex flex-col justify-center lg:w-full md:w-2/3 sm:w-full">
							<div className="flex flex-row justify-center">
								<div className="flex">
									<UtilityClass.CustomText
										textSize="large"
										fontWeight="semibold"
										color="black"
										content={"Airport Landing"}
									/>
								</div>
							</div>
							<div className="flex flex-row justify-center">
								<div className="w-full flex">
									<UtilityClass.InputTable
										rowNum={
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										}
										colNum={1}
										oneColDropdown={true}
										checkboxSmallerBottomMarginBool={true}
										placeholder={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("Rate")}
										options={[
											[
												"MTOW (per tonne)",
												"MLW (per 1000 lbs)",
												"Amount (per landing)",
											],
										]}
										internalTitle={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("Select")}
										width="full"
										value={firstTable}
										includeDefaults={defaultLandingUnit}
										defaultVals={landingUnit}
									/>
								</div>
								<div className="w-full flex">
									<UtilityClass.InputTable
										rowNum={
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										}
										colNum={1}
										indexBlack={true}
										checkboxSmallerBottomMarginBool={true}
										placeholder={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("Rate")}
										leftSquareLabelBool={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill(true)}
										leftSquareLabelText={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("USD")}
										internalTitle={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("Select")}
										width="full"
										value={secondTable}
										includeDefaults={defaultLandingRate}
										defaultVals={landingRate}
										regex={/^(0?|[1-9]\d{0,3})(\.\d{0,2})?$/}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="flex col-span-3 justify-center">
						<div className="flex flex-col justify-center lg:w-full md:w-2/3 sm:w-full">
							<div className="flex flex-row justify-center">
								<div className="flex">
									<UtilityClass.CustomText
										textSize="large"
										fontWeight="semibold"
										color="black"
										content={"Terminal Navigation"}
									/>
								</div>
							</div>
							<div className="flex flex-row justify-center">
								<div className="w-full flex">
									<UtilityClass.InputTable
										rowNum={
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										}
										colNum={1}
										oneColDropdown={true}
										checkboxSmallerBottomMarginBool={true}
										placeholder={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("Rate")}
										options={[
											["MTOW (per tonne)", "Amount (per landing and takeoff)"],
										]}
										internalTitle={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("Select")}
										width="full"
										value={thirdTable}
										includeDefaults={defaultTermNavUnit}
										defaultVals={termNavigationUnit}
									/>
								</div>
								<div className="w-full flex">
									<UtilityClass.InputTable
										rowNum={
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										}
										colNum={1}
										indexBlack={true}
										checkboxSmallerBottomMarginBool={true}
										placeholder={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("Rate")}
										leftSquareLabelBool={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill(true)}
										leftSquareLabelText={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("USD")}
										internalTitle={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("Select")}
										width="full"
										value={fourthTable}
										includeDefaults={defaultTermNavRate}
										defaultVals={termNavigationRate}
										regex={/^(0?|[1-9]\d{0,3})(\.\d{0,2})?$/}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="flex col-span-3 justify-center">
						<div className="flex flex-col justify-center lg:w-full md:w-2/3 sm:w-full">
							<div className="flex flex-row justify-center">
								<div className="flex">
									<UtilityClass.CustomText
										textSize="large"
										fontWeight="semibold"
										color="black"
										content={"En Route Navigation"}
									/>
								</div>
							</div>
							<div>
								<div className="flex flex-row justify-center  ">
									<div className="w-full">
										<UtilityClass.InputTable
											rowNum={
												packageLevel === "Basic"
													? 10
													: packageLevel === "Advanced"
													? 15
													: 20
											}
											colNum={1}
											oneColDropdown={true}
											checkboxSmallerBottomMarginBool={true}
											placeholder={Array(
												packageLevel === "Basic"
													? 10
													: packageLevel === "Advanced"
													? 15
													: 20
											).fill("Rate")}
											options={[
												[
													"MTOW (per tonne)",
													"MLW (per 1000 lbs)",
													"Amount (per landing)",
												],
											]}
											internalTitle={Array(
												packageLevel === "Basic"
													? 10
													: packageLevel === "Advanced"
													? 15
													: 20
											).fill("Select")}
											width="full"
											value={fifthTable}
											includeDefaults={defaultRouteNavUnit}
											defaultVals={routeNavigationUnit}
										/>
									</div>
									<div className="w-full">
										<UtilityClass.InputTable
											rowNum={
												packageLevel === "Basic"
													? 10
													: packageLevel === "Advanced"
													? 15
													: 20
											}
											colNum={1}
											indexBlack={true}
											checkboxSmallerBottomMarginBool={true}
											placeholder={Array(
												packageLevel === "Basic"
													? 10
													: packageLevel === "Advanced"
													? 15
													: 20
											).fill("Rate")}
											leftSquareLabelBool={Array(
												packageLevel === "Basic"
													? 10
													: packageLevel === "Advanced"
													? 15
													: 20
											).fill(true)}
											leftSquareLabelText={Array(
												packageLevel === "Basic"
													? 10
													: packageLevel === "Advanced"
													? 15
													: 20
											).fill("USD")}
											internalTitle={Array(
												packageLevel === "Basic"
													? 10
													: packageLevel === "Advanced"
													? 15
													: 20
											).fill("Select")}
											width="full"
											value={sixthTable}
											includeDefaults={defaultRouteNavRate}
											defaultVals={routeNavigationRate}
											regex={/^(0?|[1-9]\d{0,3})(\.\d{0,2})?$/}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<br />

			<div className="flex justify-center">
				<div className="grid lg:w-6/12 md:w-7/12 sm:w-full lg:grid-cols-9 sm:grid-cols-1 gap-3 justify-center">
					{windowLarge && (
						<div className="flex col-span-2 w-full">
							<div className="flex flex-col w-full">
								<div className="flex mb-10 w-full flex-row">
									<div className="flex w-full justify-center">
										<UtilityClass.CustomText
											textSize="large"
											fontWeight="semibold"
											color="black"
											content={"Routes"}
										/>
									</div>
								</div>
								<div className="flex flex-row justify-center">
									<div className=" -mt-3 mr-4 flex">
										<UtilityClass.InputTable
											indexBool={true}
											indexBlack={true}
											rowNum={
												packageLevel === "Basic"
													? 10
													: packageLevel === "Advanced"
													? 15
													: 20
											}
											colNum={0}
											checkboxSmallerBottomMarginBool={true}
											checkboxLabelBottomMarginBool={false}
											addRoutes={true}
											routesBool
											routes={getRoutes()}
											showIndex={Array(
												packageLevel === "Basic"
													? 10
													: packageLevel === "Advanced"
													? 15
													: 20
											).fill(true)}
											capitalize={true}
											value={firstTable}
											width="full"
										/>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="flex col-span-3 justify-center">
						<div className="flex flex-col justify-center">
							<div className="flex flex-row justify-center">
								<div className="flex-row w-full">
									<UtilityClass.CustomText
										textSize="large"
										fontWeight="semibold"
										color="black"
										content={"Passenger Handling\n(Per Market)"}
									/>
								</div>
							</div>
							<div className="flex flex-row justify-center">
								<div className="w-full flex">
									<UtilityClass.InputTable
										rowNum={
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										}
										colNum={1}
										indexCaptionBool={windowLarge}
										checkboxSmallerBottomMarginBool={true}
										placeholder={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("Rate")}
										leftSquareLabelBool={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill(true)}
										leftSquareLabelText={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("USD")}
										rightSquareLabelBool={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill(true)}
										rightSquareLabelText={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("per pax")}
										width="full"
										value={seventhTable}
										includeDefaults={defaultPassHandling}
										defaultVals={passHandling}
										regex={/^(0?|[1-9]\d{0,3})(\.\d{0,2})?$/}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="flex col-span-4 justify-center">
						<div className="flex flex-col justify-center lg:w-full">
							<div className="flex flex-row justify-center">
								<div className="flex">
									<UtilityClass.CustomText
										textSize="large"
										fontWeight="semibold"
										color="black"
										content={"Aircraft Handling\n(Per Market)"}
									/>
								</div>
							</div>
							<div className="flex flex-row justify-center">
								<div className="flex">
									<UtilityClass.InputTable
										rowNum={
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										}
										colNum={1}
										indexCaptionBool={windowLarge}
										checkboxSmallerBottomMarginBool={true}
										placeholder={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("Rate")}
										leftSquareLabelBool={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill(true)}
										leftSquareLabelText={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("USD")}
										width="full"
										value={eigthTable}
										includeDefaults={defaultAirHandlingRate}
										defaultVals={airHandlingRate}
										regex={/^(0?|[1-9]\d{0,3})(\.\d{0,2})?$/}
									/>
								</div>
								<div className="flex">
									<UtilityClass.InputTable
										rowNum={
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										}
										colNum={1}
										oneColDropdown={true}
										options={[["Per seat", "Per tonne", "Per Kg"]]}
										internalTitle={Array(
											packageLevel === "Basic"
												? 10
												: packageLevel === "Advanced"
												? 15
												: 20
										).fill("Select")}
										width={"full"}
										value={ninthTable}
										defaultVals={airHandlingUnit}
										includeDefaults={airHandlingUnit}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<UtilityClass.Separator />
			<UtilityClass.BackNextButtons
				nextButtonText={"NEXT"}
				backLink={"/forms/" + packageType + "/AirportAndNavigationCharges"}
				nextLink={"/forms/" + packageType + "/InputReview"}
				dispatchInputs={dispatchInputs}
				validation={true}
			/>
		</motion.div>
	);
};

export default CustomAirportNavigationCharges;
