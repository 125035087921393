import React, { useState, useEffect } from "react";
import { UtilityClass } from "../../utility-class/UtilityClass";
import { useSelector, useDispatch } from "react-redux";
import {
	setAirline,
	setScheduledStartDate,
	setMarkets,
	setSchedule,
	setCustomScheduleChoice,
} from "../../reducers/demandDataSlice";
import { motion } from "framer-motion";

const Markets = (props) => {
	const dispatch = useDispatch();

	let inputNodes = document.querySelectorAll(
		'div[data-type="input"] > div > div > div > input'
	);
	let scheduledstartDateNode = document.querySelector(
		`div[data-type="input"] > input[type="date"]`
	);

	// redux calls
	let packageLevel = useSelector((state) => state.packageLevel.value);
	let airline = useSelector((state) => state.demandData.airline);
	let scheduledStartDate = useSelector(
		(state) => state.demandData.scheduledStartDate
	);
	let markets = useSelector((state) => state.demandData.markets);
	let schedule = useSelector((state) => state.demandData.schedule);
	let customScheduleChoice = useSelector(
		(state) => state.demandData.customScheduleChoice
	);

	const [checkboxValue, setCheckboxValue] = useState(false);
	const [marketInputs, setMarketInputs] = useState();
	let temp = [];
	const marketsTableFunction = (type) => {
		temp.push(type.toUpperCase());
		setMarketInputs(type);
	};
	const breakArray = (arr, chunkSize) => {
		const out = [];
		for (let i = 0; i < arr.length; i += chunkSize) {
			const chunk = arr.slice(i, i + chunkSize);
			out.push(chunk);
		}
		return out;
	};

	// functions to save inputs to redux
	const changeAirline = (i) => {
		dispatch(setAirline(i));
		// console.log(airline);
	};
	const changeSchedStartDate = (i) => {
		dispatch(setScheduledStartDate(i));
		// console.log(scheduledStartDate);
	};
	useEffect(() => {
		console.log(breakArray(temp, packageLevel === "Basic" ? 4 : 5));
	}, [temp]);

	const changeMarkets = () => {
		const arr = breakArray(temp, packageLevel === "Basic" ? 4 : 5);
		dispatch(setMarkets(arr));
		dispatch(setCustomScheduleChoice(checkboxValue));

		// update schedule component, disabled data from markets table
		let tempSchedule = [
			packageLevel === "Basic" || packageLevel === "Advanced"
				? [
						[arr[0][0], arr[0][1], schedule[0][0][2]],
						[arr[1][0], arr[1][1], schedule[0][1][2]],
						[arr[2][0], arr[2][1], schedule[0][2][2]],
				  ]
				: [
						// [arr[0][0], arr[0][1], schedule[0][0][2]],
						[arr[0][0], arr[0][1], arr[0][4]],
						[arr[1][0], arr[1][1], arr[1][4]],
						[arr[2][0], arr[2][1], arr[2][4]],
						[arr[3][0], arr[3][1], arr[3][4]],
						[arr[4][0], arr[4][1], arr[4][4]],
				  ],
			schedule[1],
			schedule[2],
		];
		dispatch(setSchedule(tempSchedule));
	};

	const [defaultMarkets, setDefaultMarkets] = useState(
		markets === undefined || markets.length === 0 ? false : true
	);
	const [defaultCustomScheduleChoice, setDefaultCustomScheduleChoice] =
		useState(
			customScheduleChoice === undefined ||
				customScheduleChoice.length === 0 ||
				customScheduleChoice === "false" ||
				customScheduleChoice === false
				? false
				: true
		);

	useEffect(() => {
		const arr = Array.from([...inputNodes]);
		console.log(arr);
	}, []);

	// const validateContinue = () => {
	// 	let validated = true;
	// 	let increment = 0;
	// 	const invalidInputs = [];
	// 	const validMatrix = [];
	// 	let currentRow = [];
	// 	let iataInputs = Array.from([...inputNodes]).slice(
	// 		1,
	// 		packageLevel === "Basic" || packageLevel === "Advanced" ? 13 : 25
	// 	);
	// 	const operatorInput = Array.from([...inputNodes]).slice(0, 1);
	// 	// console.log(operatorInput[0]);
	// 	let newIataInputsArr = [];
	// 	let acTypeCap = [];
	// 	for (
	// 		let i = 0;
	// 		i < iataInputs.length;
	// 		i += packageLevel === "Basic" ? 4 : 5
	// 	) {
	// 		newIataInputsArr.push(iataInputs[i], iataInputs[i + 1]);
	// 	}
	// 	for (
	// 		let i = 0;
	// 		i < iataInputs.length;
	// 		i += packageLevel === "Basic" ? 4 : 5
	// 	) {
	// 		acTypeCap.push(iataInputs[i + 2], iataInputs[i + 3]);
	// 	}
	// newIataInputsArr.forEach((input, index) => {
	// 	const rowIndex = Math.floor(index / 3);
	// 	// this checks for invalid iata codes
	// 	if (input.value.length !== 3 && input.value !== "") {
	// 		invalidInputs.push(input.value.toUpperCase());
	// 	}
	// 	currentRow.push(input.value);
	// 	// console.log(currentRow);
	// 	increment++;
	// 	if (increment === 2) {
	// 		if (currentRow.every((element) => element.trim().length === 3)) {
	// 			// console.log(currentRow);
	// 			validMatrix.push(true);
	// 		} else if (!currentRow.every((element) => element === "")) {
	// 			validMatrix.push(false);
	// 		}
	// 		currentRow = [];
	// 		increment = 0;
	// 	}
	// });

	// 	acTypeCap.forEach((input, index) => {
	// 		const rowIndex = Math.floor(index / 3);
	// 		// Check for empty inputs and push them to invalidInputs
	// 		if (input.value.trim() === "") {
	// 			invalidInputs.push(input.value.toUpperCase());
	// 		}
	// 		currentRow.push(input.value);
	// 		increment++;
	// 		if (increment === 2) {
	// 			if (currentRow.every((element) => element.trim() !== "")) {
	// 				// If all inputs in the row are non-empty, mark the row as valid
	// 				validMatrix.push(true);
	// 			} else if (!currentRow.every((element) => element === "")) {
	// 				// If at least one input in the row is non-empty, but not all, mark as invalid
	// 				validMatrix.push(false);
	// 			}
	// 			currentRow = [];
	// 			increment = 0;
	// 		}
	// 	});

	// 	// console.log(invalidInputs);

	// 	if (newIataInputsArr.every((element) => element.value.length === 0)) {
	// 		validated = false;
	// 	}
	// 	// if (acTypeCap.every((element) => element.value.length === 0)) {
	// 	// 	validated = false;
	// 	// }
	// 	if (invalidInputs.length > 0) {
	// 		validated = false;
	// 	}
	// 	if (validMatrix.includes(false)) {
	// 		validated = false;
	// 	}
	// 	console.log(invalidInputs);
	// 	console.log(validMatrix);
	// 	return [validated, invalidInputs];
	// };

	// WORKING ==========================================================================
	// const validateContinue = () => {
	// 	let validated = true;
	// 	let atLeastOneRowValid = false;
	// 	const invalidInputs = [];

	// 	const iataInputs = Array.from([...inputNodes]).slice(
	// 		1,
	// 		packageLevel === "Basic" || packageLevel === "Advanced" ? 13 : 25
	// 	);

	// 	const allRows = [];
	// 	for (
	// 		let i = 0;
	// 		i < iataInputs.length;
	// 		i += packageLevel != "Premium" ? 4 : 5
	// 	) {
	// 		const row = [
	// 			iataInputs[i]?.value.trim(), // First column
	// 			iataInputs[i + 1]?.value.trim(), // Second column
	// 			iataInputs[i + 2]?.value.trim(), // Third column
	// 			iataInputs[i + 3]?.value.trim(), // Fourth column
	// 		];
	// 		allRows.push(row);
	// 	}

	// 	allRows.forEach((row, rowIndex) => {
	// 		const firstTwo = [row[0], row[1]];
	// 		const lastTwo = [row[2], row[3]];

	// 		const hasValidIATA = firstTwo.every((value) => value.length === 3);
	// 		const hasInputsInFirstTwo = firstTwo.some((value) => value !== "");
	// 		const hasInputsInLastTwo = lastTwo.some((value) => value !== "");
	// 		const areLastTwoValid = lastTwo.every((value) => value !== "");

	// 		// Check for invalid IATA codes in the first two boxes
	// 		if (hasInputsInFirstTwo && !hasValidIATA) {
	// 			invalidInputs.push(...firstTwo.filter((value) => value.length !== 3));
	// 			validated = false;
	// 		}

	// 		// Check if the first two are valid but the last two are incomplete
	// 		if (hasInputsInFirstTwo && hasValidIATA && !areLastTwoValid) {
	// 			validated = false;
	// 		}

	// 		// Ensure at least the first row is valid
	// 		if (hasValidIATA && areLastTwoValid) {
	// 			if (rowIndex === 0) {
	// 				atLeastOneRowValid = true;
	// 			}
	// 		}
	// 	});

	// 	// Ensure at least the first row is valid
	// 	if (!atLeastOneRowValid) {
	// 		validated = false;
	// 	}

	// 	// Return validation result
	// 	if (!validated || invalidInputs.length > 0) {
	// 		return [validated, invalidInputs];
	// 	}

	// 	return validated;
	// };
	const validateContinue = () => {
		let validated = true;
		let atLeastOneRowValid = false;
		const invalidInputs = [];

		// Select fields
		const inputNodes = document.querySelectorAll(
			'div[data-type="input"] > div > div > div > input'
		);
		const scheduledStartDateNode = document.querySelector(
			'div[data-type="input"] > input[type="date"]'
		);
		const operator = inputNodes[0]; // Assuming the first input is the Operator field

		// Check Operator field
		if (!operator || operator.value.trim() === "") {
			// invalidInputs.push("Operator field is empty");
			validated = false;
		}

		// Check Scheduled Start Date field
		if (!scheduledStartDateNode || scheduledStartDateNode.value.trim() === "") {
			// invalidInputs.push("Scheduled Start Date is empty");
			validated = false;
		}

		// Validate markets table inputs
		const iataInputs = Array.from([...inputNodes]).slice(
			1,
			packageLevel === "Basic" || packageLevel === "Advanced" ? 13 : 25
		);

		const allRows = [];
		for (
			let i = 0;
			i < iataInputs.length;
			i += packageLevel !== "Premium" ? 4 : 5
		) {
			const row = [
				iataInputs[i]?.value.trim(), // First column
				iataInputs[i + 1]?.value.trim(), // Second column
				iataInputs[i + 2]?.value.trim(), // Third column
				iataInputs[i + 3]?.value.trim(), // Fourth column
			];
			allRows.push(row);
		}

		allRows.forEach((row, rowIndex) => {
			const firstTwo = [row[0], row[1]];
			const lastTwo = [row[2], row[3]];

			const hasValidIATA = firstTwo.every((value) => value.length === 3);
			const hasInputsInFirstTwo = firstTwo.some((value) => value !== "");
			const hasInputsInLastTwo = lastTwo.some((value) => value !== "");
			const areLastTwoValid = lastTwo.every((value) => value !== "");

			// Check for invalid IATA codes in the first two boxes
			if (hasInputsInFirstTwo && !hasValidIATA) {
				invalidInputs.push(...firstTwo.filter((value) => value.length !== 3));
				validated = false;
			}

			// Check if the first two are valid but the last two are incomplete
			if (hasInputsInFirstTwo && hasValidIATA && !areLastTwoValid) {
				validated = false;
			}

			// Ensure at least the first row is valid
			if (hasValidIATA && areLastTwoValid) {
				if (rowIndex === 0) {
					atLeastOneRowValid = true;
				}
			}
		});

		// Ensure at least one valid row exists
		if (!atLeastOneRowValid) {
			validated = false;
		}

		// Return validation result
		if (!validated || invalidInputs.length > 0) {
			return [validated, invalidInputs];
		}

		return validated;
	};

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.1 }}
		>
			<UtilityClass.CustomText
				textSize="subtitle"
				fontWeight="medium"
				bottomMargin={false}
				color="black"
				content={`${packageLevel} Level Analysis`}
			></UtilityClass.CustomText>
			<UtilityClass.CustomText
				textSize="large"
				fontWeight="medium"
				bottomMargin={true}
				color="black"
				content={`Forecast O&D traffic  +  market share based on ${
					packageLevel === "Basic"
						? "your provided"
						: packageLevel === "Advanced"
						? "an optimized"
						: "your provided and/or an optimized"
				} schedule`}
			></UtilityClass.CustomText>
			<div className="flex lg:w-1/3 md:w-1/2 w-3/4 justify-center mx-auto gap-x-2">
				<UtilityClass.DefaultInput
					labelBool={true}
					label="Operator"
					placeholder={"IATA code / name"}
					defaultValue={airline}
					value={changeAirline}
					width="full"
					capitalize={true}
					regex={/^[a-zA-Z0-9\s]*$/}
				></UtilityClass.DefaultInput>
				<UtilityClass.DefaultInput
					labelBool={true}
					label="Scheduled Start Date"
					leftSquareLabelBool={false}
					leftSquareLabelText={"Date"}
					placeholder={"dd-mm-yyyy"}
					defaultValue={scheduledStartDate}
					value={changeSchedStartDate}
					width="full"
				></UtilityClass.DefaultInput>
			</div>
			<UtilityClass.Separator />
			<div className="w-full">
				<div className="flex w-full justify-center mx-auto gap-x-1">
					<UtilityClass.InputTable
						captionBool={true}
						label={
							<UtilityClass.CustomText
								textSize="large"
								fontWeight="medium"
								bottomMargin={true}
								color="black"
								content={`Input up to ${
									packageLevel === "Basic" || packageLevel === "Advanced"
										? "3"
										: "5"
								} markets using valid IATA codes\nFor each market, bi-directional route data will be provided`}
							></UtilityClass.CustomText>
						}
						indexBool={true}
						indexBlack={true}
						rowNum={
							packageLevel === "Basic" || packageLevel === "Advanced" ? 3 : 5
						}
						colNum={packageLevel === "Basic" ? 4 : 5}
						rowBottomMarginBool={false}
						rowConditionalMarginBoolList={false}
						headerLabelsBool={true}
						labelGray={true}
						bottomla
						redTextInLabelList={
							packageLevel === "Basic"
								? [false, true, true, false, false]
								: [false, true, true, false, false, true]
						}
						autoComplete={true}
						autoCompleteCheck={[true, true, false, false]}
						conditionalLabelsList={
							packageLevel === "Basic"
								? [
										"Market",
										"From\nIATA code",
										"To\nIATA code",
										"Aircraft\nType",
										"Aircraft\nCapacity",
								  ]
								: [
										"Market",
										"From\nIATA code",
										"To\nIATA code",
										"Aircraft\nType",
										"Aircraft\nCapacity",
										"Partner Airlines\nIATA code",
								  ]
						}
						placeholder={
							packageLevel === "Basic"
								? ["", "", "AC Type", "Seats"]
								: ["", "", "AC Type", "Seats", ""]
						}
						rightMarginBoolList={
							packageLevel === "Basic"
								? [false, false, false, false]
								: [false, true, false, true, false]
						}
						disabledBoolList={false}
						showIndex={Array(
							packageLevel === "Basic" || packageLevel === "Advanced" ? 3 : 5
						).fill(true)}
						labelBottomMarginBoolList={false}
						includeDefaults={defaultMarkets}
						defaultVals={markets}
						value={marketsTableFunction}
						width="full"
						capitalize={true}
						regex={
							packageLevel === "Basic"
								? [
										/^[a-zA-Z0-9 ]*$/,
										/^[a-zA-Z0-9 ]*$/,
										/^[a-zA-Z0-9\- ]*$/, // this was null before ?!
										/^(0?|[1-9]\d{0,2})$/,
								  ]
								: [
										/^[a-zA-Z0-9 ]*$/,
										/^[a-zA-Z0-9 ]*$/,
										/^[a-zA-Z0-9\- ]*$/, // this was null before ?!
										/^(0?|[1-9]\d{0,2})$/,
										/^[a-zA-Z0-9]*$/,
								  ]
						}
						multiRegex={true}
					/>
					{/* this is for ac type */}
					{/* <UtilityClass.InputTable
						captionBool={false}
						label={
							<UtilityClass.CustomText
								textSize="large"
								fontWeight="medium"
								bottomMargin={true}
								color="black"
								content={
									"nbsp\nFor each market, bi-directional route data will be provided"
								}
							></UtilityClass.CustomText>
						}
						// indexBool={true}
						// indexBlack={true}
						rowNum={5}
						colNum={packageLevel === "Basic" ? 1 : 1}
						rowBottomMarginBool={false}
						rowConditionalMarginBoolList={false}
						headerLabelsBool={true}
						labelGray={true}
						bottomla
						redTextInLabelList={packageLevel === "Basic" ? [false] : [false]}
						// autoComplete={true}
						conditionalLabelsList={
							packageLevel === "Basic"
								? ["Aircraft\nType", "Aircraft\nType"]
								: ["Aircraft\nType", "Aircraft\nType"]
						}
						placeholder={packageLevel === "Basic" ? ["AC Type"] : ["AC Type"]}
						rightMarginBoolList={packageLevel === "Basic" ? [false] : [false]}
						disabledBoolList={false}
						showIndex={Array(5).fill(true)}
						labelBottomMarginBoolList={false}
						includeDefaults={defaultMarkets}
						defaultVals={markets}
						value={marketsTableFunction}
						// width="full"
						capitalize={true}
						regex={
							packageLevel === "Basic"
								? [
										/^[a-zA-Z0-9\- ]*$/, // this was null before ?!
								  ]
								: [
										/^[a-zA-Z0-9\- ]*$/, // this was null before ?!
								  ]
						}
						// multiRegex={true}
					/> */}
				</div>
			</div>
			{packageLevel === "Basic" || packageLevel === "Advanced" ? (
				<UtilityClass.BackNextButtons
					nextButtonText="NEXT"
					nextLink={`${
						packageLevel === "Basic"
							? "/forms/Demand/Schedule"
							: "/forms/Demand/InputReview"
					}`}
					backButtonBool={false}
					dispatchInputs={changeMarkets}
					// validation={validateContinue()}
					// validationData={true}
					validation={validateContinue()}
					validationData={validateContinue()[0] == false}
				/>
			) : (
				<>
					<UtilityClass.Checkbox
						text="Check if you want to provide your own schedule on specific markets"
						value={setCheckboxValue}
						defaultValue={JSON.parse(defaultCustomScheduleChoice)}
						required={true}
					/>
					<UtilityClass.BackNextButtons
						nextButtonText="NEXT"
						nextLink={`${
							checkboxValue
								? "/forms/Demand/Schedule"
								: "/forms/Demand/InputReview"
						}`}
						backButtonBool={false}
						dispatchInputs={changeMarkets}
						validation={validateContinue()}
						validationData={validateContinue()[0] == false}
					/>
				</>
			)}
		</motion.div>
	);
};

export default Markets;
